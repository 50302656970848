import { memo, useCallback, useContext, useState } from 'react'
import { useHistory } from "react-router-dom"
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Tooltip from '@material-ui/core/Tooltip'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import SubjectIcon from '@material-ui/icons/Subject'
import { useMutation } from '@apollo/client'

import useEditingLock from '../../../hooks/useEditingLock'
import useStickyRefState from '../../../hooks/useStickyRefState'
import useEffectAsync from '../../../hooks/useEffectAsync'
import useSetTimeout from '../../../hooks/useSetTimeout'
import useAccountSetting from '../../../hooks/useAccountSetting'
import useInstanceValuesCallback from '../../../hooks/useInstanceValuesCallback'
import useSimpleToggle from '../../../hooks/useSimpleToggle'
import { cloneObj, getPrimaryName, getShortUuid, sortEventsByDate } from '../../../utils/misc'
import mapDataIsValid from './mapDataIsValid'
import { LoggedInUserContext } from '../../../context/LoggedInUser'

import BibleMapEditorPlace from './BibleMapEditorPlace'
import BibleMapEditorButtons from './BibleMapEditorButtons'
import BibleMapEditorBody from './BibleMapEditorBody'
import BibleMapEditorEvent from './BibleMapEditorEvent'
import BibleMapEditorJourney from './BibleMapEditorJourney'
import BibleMapEditorPerson from './BibleMapEditorPerson'
import BibleMapPlaceInfo from './BibleMapPlaceInfo'
import BibleMapJourneyInfo from './BibleMapJourneyInfo'
import BibleMapPersonInfo from './BibleMapPersonInfo'
import CustomSwitch from '../../common/CustomSwitch'
import InfoDialog from '../../common/InfoDialog'
import MyPlanPromoSpot from '../../common/MyPlanPromoSpot'

import updateMapLayerMutation from '../../../graphql/mutations/updateMapLayer'

export const TransitionRight = props => <Slide {...props} direction="right" />

const EditingSwitchContainer = styled.div`
  padding: 3px 15px;
  border-radius: 5px 5px 0 0;
  background: ${({ theme }) => theme.palette.grey[100]};
  border-bottom: .5px solid ${({ theme }) => theme.palette.divider};
  display: flex;
  justify-content: flex-end;
  user-select: none;
`

const StyledSnackbar = styled(Snackbar)`

  z-index: 100;
  margin-top: 60px;

  @media (max-width: 600px) {
    top: 10px;
    left: 10px;
    right: auto;
  }

  .MuiSnackbarContent-root {
    min-width: 0;
    max-width: none;
    max-height: calc(100dvh - 60px - 50px);
    padding: 0;
    flex-direction: column;
    justify-content: center;
    background: white;
    color: black;

    @media (max-width: 600px) {
      max-height: calc(100dvh - 60px - 17px);
    }
  }

  .MuiSnackbarContent-message {
    width: 300px;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex: 1;
  }

  .MuiSnackbarContent-action {
    display: none;
  }

`

const Heading = styled.div`
  padding: 15px;
  display: flex;
  align-items: flex-start;
`

const HeadingMain = styled.div`
  position: relative;
  flex: 1;
  min-width: 0;
`

const Title = styled.div`
  font-size: 17px;
`

const InUse = styled.div`
  font-weight: bold;
`

const InUseExplanation = styled.div`
  margin-top: 10px;
`

const BackButton = styled(Button)`
  font-size: 10px;
  padding: 2px 14px 2px 4px;
  margin: 0 0 10px;
  max-width: 100%;
  justify-content: flex-start;

  .MuiSvgIcon-root {
    font-size: 11px;
    position: absolute;
    left: 10px;
    top: 5px;
  }

  .MuiButton-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    max-width: 100%;
    padding-left: 25px;
    display: block;
  }
`

const MoveButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  font-size: 10px;
  padding: 2px 14px;
`

const StyledIconButton = styled(IconButton)`
  padding: 10px;
  margin: -7px -7px -7px 5px;

  .MuiSvgIcon-root {
    font-size: 18px;    
  }
`

const MovingPlaceInsructions = styled.div`
  padding: 0 15px 15px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.grey[600]};
`

const BibleMapSnackbar = ({
  open,
  viewDraft,
  mapLayerId,
  setMapLayer,
  closeSelected,
  places,
  journeys,
  persons,
  movingPlace,
  toggleMovingPlace,
  eventsByJourneyId,
  selectedPlace,
  selectedJourney,
  selectedPerson,
  expandedEventId,
  setSelectedPlace,
  setSelectedJourney,
  setSelectedPerson,
  setExpandedEventId,
  mapLayerIds,
  ...otherProps
}) => {

  const history = useHistory()

  const user = useContext(LoggedInUserContext)
  const canEdit = viewDraft && [ 'ADMIN', 'EDITOR' ].includes((user || {}).adminLevel)
  let [ editing, setEditing ] = useStickyRefState({ id: `BibleMapSnackbar:editing`, defaultValue: false })
  editing = !!(editing && canEdit)
  const toggleEditing = useCallback(() => setEditing(!editing), [ editing, setEditing ])
  const [ setBackToPlaceTimeout ] = useSetTimeout()
  const [ dialogOpen, toggleDialogOpen ] = useSimpleToggle()

  const [ mapNotePlace, setMapNotePlace ] = useAccountSetting(`map-note-place-${(selectedPlace || {}).id}`, {}, { skip: !selectedPlace })
  const [ mapNoteJourney, setMapNoteJourney ] = useAccountSetting(`map-note-jrney-${(selectedJourney || {}).id}`, {}, { skip: !selectedJourney })
  const [ mapNotePerson, setMapNotePerson ] = useAccountSetting(`map-note-prsn-${(selectedPerson || {}).id}`, {}, { skip: !selectedPerson })

  const layer = `BIBLE`

  const {
    requestingLock,
    lockObtained,
    requestId,
    lockedMsg,
    lockedExplanation,
  } = useEditingLock({ type: `MAP:${layer}`, open: open && canEdit })

  const [ updateMapLayer ] = useMutation(updateMapLayerMutation)

  const [ eventInEdit, setEventInEdit ] = useState()
  
  const [ updating, setUpdating ] = useState()

  const { search } = selectedJourney || selectedPerson || selectedPlace || {}

  const goSearch = useCallback(
    () => {
      history.replace({
        hash: `#search=${encodeURIComponent(search)}`,
        state: {
          executeSearch: true,
        },
      })
    },
    [ history, search ],
  )

  const doUpdateMapLayer = useCallback(
    async ({ place, event, journey, person, placeId }) => {

      setUpdating(true)

      let goSet

      const getUpdatedItem = (item, itemsSet, setFunc) => {
        if(item) {
          const updatedSet = [ ...itemsSet ]
          const clonedItem = cloneObj(item)
          clonedItem.id = clonedItem.id || getShortUuid(mapLayerIds)
          const idx = itemsSet.findIndex(({ id }) => id === clonedItem.id)
          if(clonedItem.delete) {
            if(idx !== -1) {
              updatedSet.splice(idx, 1)
            }
          } else if(idx === -1) {
            if(item === place) {
              clonedItem.events = []
            }
            updatedSet.push(clonedItem)
          } else {
            if(updatedSet[idx].events) {
              clonedItem.events = updatedSet[idx].events
            }
            updatedSet[idx] = clonedItem
          }
          if(!clonedItem.delete) {
            goSet = () => setFunc(clonedItem)
          }
          return updatedSet
        } else if(itemsSet.events && event) {
          return getUpdatedItem(event, itemsSet.events)
        } else {
          return itemsSet
        }
      }

      const data = {
        places: getUpdatedItem(place, places, setSelectedPlace),
        journeys: getUpdatedItem(journey, journeys, setSelectedJourney),
        persons: getUpdatedItem(person, persons, setSelectedPerson),
      }

      if(event && placeId) {
        data.places = [ ...data.places ]
        const idx = data.places.findIndex(({ id }) => id === placeId)
        data.places[idx] = { ...data.places[idx] }
        const setFunc = clonedEvent => {
          setSelectedPlace(data.places[idx])
          setEventInEdit(clonedEvent)
        }
        if(event.delete) {
          goSet = () => setSelectedPlace(data.places[idx])
        }
        data.places[idx].events = getUpdatedItem(event, data.places[idx].events, setFunc)
        sortEventsByDate(data.places[idx])
      }

      if(journey) {
        data.journeys.sort((a,b) => getPrimaryName(a) < getPrimaryName(b) ? -1 : 1)
      }

      if(person) {
        data.persons.sort((a,b) => getPrimaryName(a) < getPrimaryName(b) ? -1 : 1)
      }

      try {
        mapDataIsValid(data)
      } catch(e) {
        alert(e.message || `Invalid map data: unknown reason`)
        setUpdating(false)
        return
      }

      const { data: { updateMapLayer: newCreatedAt } } = await updateMapLayer({
        variables: {
          layer,
          data,
          editingLockRequestId: requestId,
        },
      })

      setMapLayer({
        createdAt: newCreatedAt,
        data,
      })

      goSet && goSet()

      setUpdating(false)

    },
    [ layer, setUpdating, places, journeys, persons, requestId, setMapLayer, updateMapLayer, setSelectedPlace, setSelectedJourney, setSelectedPerson, setEventInEdit, mapLayerIds ],
  )

  const backToPlace = useCallback(
    () => {
      setEventInEdit()
      setSelectedJourney()
      setSelectedPerson()
    },
    [ setEventInEdit, setSelectedJourney, setSelectedPerson ],
  )

  const backToEvent = useCallback(
    () => {
      setSelectedJourney()
      setSelectedPerson()
    },
    [ setSelectedJourney, setSelectedPerson ],
  )

  useEffectAsync(
    () => {
      if(!open) {
        setBackToPlaceTimeout(backToPlace, 500)
      }
    },
    [ open ],
  )

  useEffectAsync(
    () => {
      backToPlace()
    },
    [ (selectedPlace || {}).id ],
  )

  useEffectAsync(
    async () => {
      if(movingPlace && (selectedPlace || {}).id) {
        await doUpdateMapLayer({ place: selectedPlace })
        toggleMovingPlace()
      }
    },
    [ selectedPlace ],
  )

  const editingTitle = (
    (selectedJourney && (selectedJourney.id ? i18n("Edit Journey") : i18n("Create Journey")))
    || (selectedPerson && (selectedPerson.id ? i18n("Edit Person") : i18n("Create Person")))
    || (eventInEdit && (eventInEdit.id ? i18n("Edit Event") : i18n("Create Event")))
    || ((selectedPlace || {}).id ? i18n("Edit Place") : i18n("Create Place"))
  )

  const showingPlace = !eventInEdit && !selectedJourney && !selectedPerson
  const backGoesToEvent = !!(selectedJourney || selectedPerson) && editing

  const BibleMapEditorPiece = (
    (showingPlace && BibleMapEditorPlace)
    || (selectedJourney && BibleMapEditorJourney)
    || (selectedPerson && BibleMapEditorPerson)
    || (eventInEdit && BibleMapEditorEvent)
  )

  const BibleMapPieceInfo = (
    (showingPlace && BibleMapPlaceInfo)
    || (selectedJourney && BibleMapJourneyInfo)
    || (selectedPerson && BibleMapPersonInfo)
  )

  const showAddANote = (
    !viewDraft
    && !editing
    && (
      (showingPlace && mapNotePlace.note === undefined)
      || (!!selectedJourney && mapNoteJourney.note === undefined)
      || (!!selectedPerson && mapNotePerson.note === undefined)
    )
  )

  const createMapNote = useInstanceValuesCallback(
    () => {
      if((user || {}).hasMyPlan) {
        if(showingPlace) {
          setMapNotePlace({ ...mapNotePlace, note: `` })
        } else if(selectedJourney) {
          setMapNoteJourney({ ...mapNoteJourney, note: `` })
        } else if(selectedPerson) {
          setMapNotePerson({ ...mapNotePerson, note: `` })
        }
      } else {
        toggleDialogOpen({ force: true })
      }
    }
  )

  return (
    <>

      <StyledSnackbar
        open={open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        TransitionComponent={TransitionRight}
        message={
          <>

            {canEdit &&
              <EditingSwitchContainer>
                <CustomSwitch
                  checked={editing}
                  onChange={toggleEditing}
                  label={i18n("Editing")}
                />
              </EditingSwitchContainer>
            }

            <Heading>
              <HeadingMain>
                {!showingPlace &&
                  <BackButton
                    onClick={backGoesToEvent ? backToEvent : backToPlace}
                    variant="contained"
                    disableElevation
                    startIcon={<ArrowBackIcon />}
                  >
                    {i18n("Back to {{item}}", { item: backGoesToEvent ? getPrimaryName(eventInEdit) : getPrimaryName(selectedPlace) })}
                  </BackButton>
                }
                {editing && showingPlace && lockObtained && (selectedPlace || {}).id &&
                  <MoveButton
                    onClick={toggleMovingPlace}
                    variant="contained"
                    disableElevation
                  >
                    {movingPlace ? i18n("Cancel Move") : i18n("Move the Dot")}
                  </MoveButton>
                }
                <Title>
                  {editing ? editingTitle : getPrimaryName(selectedJourney || selectedPerson || selectedPlace || {})}
                </Title>
              </HeadingMain>
              {showAddANote &&
                <Tooltip
                  title={i18n("Add a note")}
                >
                  <StyledIconButton
                    onClick={createMapNote}
                  >
                    <SubjectIcon />
                  </StyledIconButton>
                </Tooltip>
              }
              {!editing && !!search &&
                <Tooltip
                  title={
                    showingPlace
                      ? i18n("Search the Bible for this place")
                      : i18n("Search the Bible for this person")
                  }
                >
                  <StyledIconButton
                    onClick={goSearch}
                  >
                    <SearchIcon />
                  </StyledIconButton>
                </Tooltip>
              }
              {!editing &&
                <StyledIconButton
                  className="BibleMapSnackbar-CloseIconButton"
                  onClick={closeSelected}
                >
                  <CloseIcon />
                </StyledIconButton>
              }
            </Heading>

            {editing && !requestingLock && !lockObtained &&
              <>
                <BibleMapEditorBody>
                  <InUse>
                    {lockedMsg}
                  </InUse>
                  <InUseExplanation>
                    {lockedExplanation}
                  </InUseExplanation>
                </BibleMapEditorBody>
                <BibleMapEditorButtons
                  closeSelected={closeSelected}
                  requestingLock={requestingLock}
                  lockObtained={lockObtained}
                />
              </>
            }

            {editing &&(requestingLock || lockObtained) && !movingPlace &&
              <BibleMapEditorPiece
                placeInEdit={selectedPlace}
                eventInEdit={eventInEdit}
                journeyInEdit={selectedJourney}
                personInEdit={selectedPerson}
                mapLayerId={mapLayerId}
                doUpdateMapLayer={doUpdateMapLayer}
                requestingLock={requestingLock}
                lockObtained={lockObtained}
                updating={updating}
                backToPlace={backToPlace}
                backToEvent={backToEvent}
                setEventInEdit={setEventInEdit}
                setSelectedJourney={setSelectedJourney}
                setSelectedPerson={setSelectedPerson}
                placeId={(selectedPlace || {}).id}
                journeys={journeys}
                persons={persons}
                places={places}
                closeSelected={closeSelected}
                eventsByJourneyId={eventsByJourneyId}
                setSelectedPlace={setSelectedPlace}
                mapLayerIds={mapLayerIds}
              />
            }

            {editing && (requestingLock || lockObtained) && movingPlace &&
              <MovingPlaceInsructions>
                {i18n("Click on the map to select a new spot for this place.")}
              </MovingPlaceInsructions>
            }

            {!editing &&
              <BibleMapPieceInfo
                key={(selectedJourney || selectedPerson || selectedPlace || {}).id}
                place={selectedPlace}
                journey={selectedJourney}
                person={selectedPerson}
                expandedEventId={expandedEventId}
                backToPlace={backToPlace}
                setSelectedJourney={setSelectedJourney}
                setSelectedPerson={setSelectedPerson}
                setSelectedPlace={setSelectedPlace}
                setExpandedEventId={setExpandedEventId}
                journeys={journeys}
                persons={persons}
                places={places}
                eventsByJourneyId={eventsByJourneyId}
                mapNotePlace={viewDraft ? null : mapNotePlace}
                setMapNotePlace={setMapNotePlace}
                mapNoteJourney={viewDraft ? null : mapNoteJourney}
                setMapNoteJourney={setMapNoteJourney}
                mapNotePerson={viewDraft ? null : mapNotePerson}
                setMapNotePerson={setMapNotePerson}
                toggleMyPlanPromoDialogOpen={toggleDialogOpen}
              />
            }

          </>
        }
        {...otherProps}
      />

      <InfoDialog
        open={dialogOpen}
        onOkay={toggleDialogOpen}
        showCloseIconButton
        title={i18n("Add a Note to Maps")}
        explanation={
          <MyPlanPromoSpot
            subscribeToMessage={i18n("Subscribe to add notes to maps.")}
          />
        }
        fullScreenWhenMobileSize
      />

    </>
  )
}

export default memo(BibleMapSnackbar)