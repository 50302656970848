import { memo, useCallback, useState, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { useMutation, useApolloClient } from '@apollo/client'

import useMutationContext from '../../../hooks/useMutationContext'

import ConfirmDialog from '../../common/ConfirmDialog'
import CustomRadioGroup from '../../common/CustomRadioGroup'
import CountrySelect from '../whatever-you-can-afford/CountrySelect'

import updateChannelMutation from '../../../graphql/mutations/updateChannel'
import useSimpleToggle from '../../../hooks/useSimpleToggle'
import useSetTimeout from '../../../hooks/useSetTimeout'

import channelQuery from '../../../graphql/queries/channel'

const PAYMENT_LINKS = {
  staging: {
    "0-99": `https://buy.stripe.com/test_5kAbKp7VmenF4zC5kl?client_reference_id=`,
    "100-399": `https://buy.stripe.com/test_14k15LcbC6Vd6HKdQS?client_reference_id=`,
    "400-999": `https://buy.stripe.com/test_eVa5m16RidjB1nq9AD?client_reference_id=`,
    "1000+": `https://buy.stripe.com/test_8wM01Hb7ybbt5DG7sw?client_reference_id=`,
  },
  production: {
    "0-99": `https://buy.stripe.com/3cs2a5bN6gLvdkkbIO?client_reference_id=`,
    "100-399": `https://buy.stripe.com/4gw2a57wQbrb4NO4gn?client_reference_id=`,
    "400-999": `https://buy.stripe.com/7sIcOJ5oI8eZbccaEM?client_reference_id=`,
    "1000+": `https://buy.stripe.com/28obKF2cwgLv6VWeV3?client_reference_id=`,
  },
}
PAYMENT_LINKS.development = PAYMENT_LINKS.staging

const StyledConfirmDialog = styled(ConfirmDialog)`
`

const Container = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
`

const FreeForDev = styled.div`
  font-size: 15px;
  margin: 20px 0 10px;
  background: ${({ theme }) => theme.palette.primary.faded}88;
  border-radius: 5px;
  align-self: center;
  padding: 7px 15px;
`

const LinkLikeSpan = styled.span`
  font-style: normal;
  display: inline-block;
  color: ${({ theme }) => theme.palette.grey[500]};
  transition: .15s ease-in-out color;
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.main};
    cursor: pointer;
  }
`

const Explanation = styled.div`
  margin: 0 0 15px;
`

const LegalStuff = styled.div`
  margin: 20px 0 5px;
  font-size: 12px;
  font-weight: 300;
`

const LegalStuffHeading = styled.div`
  font-weight: 500;
  margin-bottom: 3px;
`

const YesOrNo = styled.div`
  font-size: 18px;
  margin: 0 0 20px;
  text-align: center;
  font-weight: 500;
`

const PayForCSSBDialog = ({
  channelId,
  onCancel,
  ...otherProps
}) => {

  const [ updateChannel ] = useMutation(updateChannelMutation)
  const context = useMutationContext()
  const client = useApolloClient()

  const [ loading, setLoading ] = useState(false)
  const [ churchSize, setChurchSize ] = useState()
  const [ showDevWorld, toggleShowDevWorld ] = useSimpleToggle()
  const [ country, setCountry ] = useState(null)
  const [ setCheckForPurchaseTimeout ] = useSetTimeout()

  const churchSizeRadios = useMemo(
    () => (
      [
        {
          value: `0-99`,
          label: i18n("Up to 99 ($100)"),
        },
        {
          value: `100-399`,
          label: i18n("100–399 ($250)"),
        },
        {
          value: `400-999`,
          label: i18n("400–999 ($400)"),
        },
        {
          value: `1000+`,
          label: i18n("1000+ ($750)"),
        },
      ]
    ),
    [],
  )

  const onChurchSizeChange = useCallback(({ target }) => setChurchSize(target.value), [])
  const onCountryChange = useCallback((event, country) => setCountry(country), [])

  const onConfirm = useCallback(
    async () => {

      setLoading(true)

      const paymentUrl = `${PAYMENT_LINKS[process.env.REACT_APP_STAGE][churchSize]}${encodeURIComponent(channelId)}`
      window.open(paymentUrl, '_blank')

      await new Promise(resolve => {

        const checkUntil = Date.now() + 1000*60*10  // max out at 10 minutes

        const checkForPayment = async () => {
          const { data: { channel } } = await client.query({
            query: channelQuery,
            variables: {
              id: channelId,
            },  
            fetchPolicy: 'network-only',
          })

          if(channel.purchasedAt) return resolve()

          if(Date.now() < checkUntil) {
            setCheckForPurchaseTimeout(checkForPayment, 1000*3)
          } else {
            resolve()  // give up
          }
        }

        checkForPayment()

      })

      setLoading(false)
      onCancel()

    },
    [ setLoading, churchSize, channelId, onCancel, client, setCheckForPurchaseTimeout ],
  )

  const onConfirmFree = useCallback(
    async () => {

      setLoading(true)

      await updateChannel({
        variables: {
          id: channelId,
          input: {
            purchaseInfo: {
              country,
              amount_total: 0,
            },
          },
        },
        context,
      })

      setLoading(false)
      onCancel()

    },
    [ setLoading, updateChannel, channelId, context, onCancel, country ],
  )

  return (
    <StyledConfirmDialog
      {...otherProps}
      title={i18n("Complete Your Study Bible Setup")}
      confirmButtonLabel={showDevWorld ? i18n("Complete Setup for Free") : i18n("Checkout via Stripe")}
      cancelButtonLabel={showDevWorld ? i18n("Back") : i18n("Cancel")}
      disabled={showDevWorld ? !(country || {}).qualifies : !churchSize}
      onConfirm={showDevWorld ? onConfirmFree : onConfirm}
      onCancel={showDevWorld ? toggleShowDevWorld : onCancel}
      explanation={
        <Container>

          {!showDevWorld &&
            <>

              <Explanation>
                {i18n("How many people typically attend your main worship service?")}
              </Explanation>

              <CustomRadioGroup
                value={churchSize}
                onChange={onChurchSizeChange}
                radios={churchSizeRadios}
              />

              <LegalStuff>
                <LegalStuffHeading>
                  {i18n("Legal Stuff")}
                </LegalStuffHeading>
                {i18n("Paying the one-time setup fee entitles your church to make use of your study Bible “as-is” so long as your church remains committed to Biblearc’s Partnership Statement of Faith, and so long as this feature is maintained by Biblearc.")}
                {` `}
                {i18n("In the event this feature is discontinued less than one year after your purchase, you will be entitled to a proportionate refund.")}
              </LegalStuff>

              <FreeForDev>
                {i18n("Free for churches in the developing world!")}
                {` `}
                <LinkLikeSpan onClick={toggleShowDevWorld}>
                  {i18n("Apply")}
                </LinkLikeSpan>
              </FreeForDev>

            </>
          }

          {showDevWorld &&
            <>

              <Explanation>
                {i18n("Select your church’s country to see if you qualify for free setup.")}
              </Explanation>

              <CountrySelect
                value={country}
                onChange={onCountryChange}
                disabled={!!country}
                label={i18n("Country your church is located in")}
              />

              {!!country &&
                <YesOrNo>
                  {
                    (country || {}).qualifies
                      ? i18n("Good news! Your country qualifies.")
                      : i18n("Sorry. Your country does not qualify.")
                  }
                </YesOrNo>
              }

            </>
          }

        </Container>
      }
      color="secondary"
      loading={loading}
    />
  )
}

export default memo(PayForCSSBDialog)