import { memo, useMemo, useState } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import { cloneObj, sortEventsByDate } from '../../../utils/misc'

import BibleMapInfoName from './BibleMapInfoName'
import BibleMapInfoBody from './BibleMapInfoBody'
import BibleMapInfoEvent from './BibleMapInfoEvent'
import BibleMapNote from './BibleMapNote'

const About = styled.div`
`

const Heading = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
`

const AboutContent = styled.div`
  font-weight: 300;
  color: ${({ theme }) => theme.palette.grey[700]};
`

const Events = styled.div`
`

const EventItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 5px 0;
`

const BibleMapPersonInfo = ({
  person,
  places,
  journeys,
  persons,
  setSelectedJourney,
  setSelectedPerson,
  setSelectedPlace,
  mapNotePerson,
  setMapNotePerson,
}) => {

  const [ expandedEventId, setExpandedEventId ] = useState()

  const events = useMemo(
    () => {
      const events = []

      places.forEach(place => {
        place.events.forEach(event => {
          if(event.personIds.includes(person.id)) {
            events.push({
              ...cloneObj(event),
              place,
            })
          }
        })
      })
      sortEventsByDate({ events })

      return events
    },
    [ places, person.id ],
  )

  return (
    <BibleMapInfoBody>

      {person.names.map(({ id, ...nameInfo }, idx) => (
        <BibleMapInfoName
          key={id}
          type="person"
          skipLabel={idx === 0}
          {...nameInfo}
        />
      ))}

      {!!person.about &&
        <About>
          <Heading>
            {i18n("About")}
          </Heading>
          <AboutContent>
            {person.about}
          </AboutContent>
        </About>
      }

      <BibleMapNote
        key={person.id}
        mapNoteItem={mapNotePerson}
        setMapNote={setMapNotePerson}
      />

      <Events>
        <Heading>
          {i18n("Events Involved In")}
        </Heading>
        <EventItems>
          {events.map(event => (
            <BibleMapInfoEvent
              key={event.id}
              {...event}
              explanded={expandedEventId === event.id}
              setExpandedEventId={setExpandedEventId}
              setSelectedJourney={setSelectedJourney}
              setSelectedPerson={setSelectedPerson}
              journeys={journeys}
              persons={persons}
              includePlace
              setSelectedPlace={setSelectedPlace}
            />
          ))}
        </EventItems>
      </Events>

    </BibleMapInfoBody>
  )
}

export default memo(BibleMapPersonInfo)