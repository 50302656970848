import { memo, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { i18n, getLocale } from 'inline-i18n'
import { useNetworkState } from 'react-use'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CheckIcon from '@material-ui/icons/Check'
import { useMutation } from '@apollo/client'
import { useStripe } from '@stripe/react-stripe-js'

import {
  PRICING,
  GROUP_PRICING_DISCOUNT,
} from '../../../utils/constants'
import { getSubscriptionPlanImageSrc, isValidEmail } from '../../../utils/misc'
import useDataQuery from '../../../hooks/useDataQuery'
import useIsLoggedIn from '../../../hooks/useIsLoggedIn'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useMutationContext from '../../../hooks/useMutationContext'
import useThrottleCallback from '../../../hooks/useThrottleCallback'
import useEffectAsync from '../../../hooks/useEffectAsync'

import Header from "../../common/Header"
import CustomRadioGroup from '../../common/CustomRadioGroup'
import FadedLoading from '../../common/FadedLoading'
import SaveStatusTextField from '../../common/SaveStatusTextField'
import ContactUsFab from '../../common/ContactUsFab'
import SignInButton from '../../common/SignInButton'

import groupSubscriptionsQuery from '../../../graphql/queries/groupSubscriptions'
import createStripeCheckoutSessionMutation from '../../../graphql/mutations/createStripeCheckoutSession'
import inviteGroupSubscriptionMembersMutation from '../../../graphql/mutations/inviteGroupSubscriptionMembers'
import updateGroupSubscriptionMutation from '../../../graphql/mutations/updateGroupSubscription'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px 80px;
  background-color: white;
`

const Content = styled.div`
  max-width: 600px;
  margin: 0 auto;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  padding-bottom: 300px;
  align-items: center;
`

const What = styled.div`
  margin: 40px 0 20px;
  padding: 15px 20px 200px;
  background-image: url("/group_subscriptions.jpg");
  background-position-y: bottom;
  background-position-x: right;
  background-size: cover;
`

const Explanation = styled.div`
  text-align: left;
`

const Step = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 40px 0 20px;

  ${({ $disabled, theme }) => !$disabled ? `` : `
    color: ${theme.palette.grey[500]};
  `}
`

const Pricing = styled.div`
  margin: 20px 0 10px;
`

const PricingRow = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  justify-content: center;
  padding: 8px 0;

  &:first-child, &:nth-child(2) {
    border: none;
  }
`

const PricingRowNum = styled.div`
  text-align: right;
  margin-right: 10px;
  width: 160px;
  font-weight: 300;
`

const PricingRowAnnualDiscount = styled.div`
  font-weight: 500;
  min-width: 70px;
  padding: 0 4px;
  text-align: center;
  white-space: nowrap;
`

const PricingRow3MonthDiscount = styled(PricingRowAnnualDiscount)`
  margin-right: 20px;
`

const PricingRowAnnualDiscountHeading = styled(PricingRowAnnualDiscount)`
  font-weight: 300;
  opacity: .7;
  font-size: 11px;
  text-transform: uppercase;

`

const PricingRow3MonthDiscountHeading = styled(PricingRow3MonthDiscount)`
  font-weight: 300;
  opacity: .7;
  font-size: 11px;
  text-transform: uppercase;
`

const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 0 30px;
  margin-top: 20px;
`

const LoadingContainer = styled.div`
  height: 200px;
  width: 100%;
  position: relative;
`

const PlanImage = styled.img`
  height: 35px;
  position: relative;
  top: 2px;
  margin-left: -5px;
`

const StyledCustomRadioGroup = styled(CustomRadioGroup)`
  margin: 10px 0;

  .MuiFormControlLabel-label {
    opacity: .35;
  }

  .Mui-checked + .MuiFormControlLabel-label {
    opacity: 1;
    color: black;
  }
`

const StyledTextField = styled(TextField)`
  width: 120px;
  margin: 20px 0;
  
  input {
    font-size: 32px;
    text-align: center;
  }
`

const Remain = styled.div`
  font-size: 14px;
  margin: -15px 0 5px;
  font-weight: 300;
`

const Total = styled.div`
  font-size: 18px;
  margin-top: 10px;
`

const TotalBreakdown = styled.div`
  font-weight: 300;
`

const CheckoutButtonContainer = styled.div`
  margin: 20px 0 25px;
`

const StyledSignInButton = styled(SignInButton)`
  margin: 20px 0 25px;
`

const GroupNameTextField = styled(SaveStatusTextField)`
  margin-top: 15px;
  width: 250px;
`

const EmailsToDistributeToTextField = styled(TextField)`
  width: 400px;
  max-width: calc(100vw - 40px);
  margin: 20px 0;
`

const DistributeButtonContainer = styled.div`
`

const SendMoreButton = styled(Button)`
  margin-left: 10px;
`

const DistributionExplanation = styled.div`
  margin-top: 25px;
  font-size: 12px;
  width: 400px;
  max-width: calc(100vw - 40px);
  padding: 0 20px;

  ${({ $disabled, theme }) => !$disabled ? `` : `
    color: ${theme.palette.grey[500]};
  `}
`

const GroupMembersHeading = styled.div`
  margin: 40px 0 10px;
  font-weight: 500;
`

const GroupMember = styled.div`
  font-size: 14px;
  font-weight: 300;
`

const GroupMemberEmail = styled.span`
  font-weight: 400;
  display: inline-block;
  margin: 0 5px;
`

const NoGroupMembers = styled.div`
  font-size: 14px;
  font-weight: 300;
`

const FAQ = styled.span`
  font-size: 17px;
  font-weight: 300;
`

const JustPurchasedNote = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.tertiary.main};
  max-width: 350px;
  margin: 15px 0 0;
  font-weight: 500;
`

const GroupSubscriptions = ({ ...props }) => {

  const isLoggedIn = useIsLoggedIn()
  const { online } = useNetworkState()
  const context = useMutationContext()

  const { groupSubscriptions: groupSubscriptionsAndCount, loading: groupSubscriptionsLoading } = useDataQuery({
    groupSubscriptionsQuery,
    skip: !isLoggedIn,
    dataOnError: null,
  })

  const [ createStripeCheckoutSession ] = useMutation(createStripeCheckoutSessionMutation)
  const [ inviteGroupSubscriptionMembers ] = useMutation(inviteGroupSubscriptionMembersMutation)
  const [ updateGroupSubscription ] = useMutation(updateGroupSubscriptionMutation)
  const stripe = useStripe()

  const groupSubscriptions = useMemo(
    () => (groupSubscriptionsAndCount || {}).groupSubscriptions || [],
    [ groupSubscriptionsAndCount ],
  )

  const [ loading, setLoading ] = useState(false)
  const [ tab, setTab ] = useState(null)
  const [ term, setTerm ] = useState(`3MONTH`)  // or ANNUAL
  const [ plan, setPlan ] = useState(`TOOLS`)
  const [ groupSize, setGroupSize ] = useState(10)
  const [ groupName, setGroupName ] = useState(``)
  const [ emailsToDistributeTo, setEmailsToDistributeTo ] = useState(``)
  const [ invitesSent, setInvitesSent ] = useState(false)

  const getEmailsToDistributeTo = useInstanceValue(emailsToDistributeTo)

  const purchaseIsComplete = tab !== `new`
  const selectedGroupSubscription = groupSubscriptions.find(({ id }) => id === tab)

  const selectedTerm = (selectedGroupSubscription || {}).term || term
  const selectedPlan = (selectedGroupSubscription || {}).plan || plan
  const selectedGroupSize = (selectedGroupSubscription || {}).quantity || parseInt(groupSize, 10) || 0
  const selectedGroupName = groupName || (selectedGroupSubscription || {}).name || ``
  const selectedMembers = (selectedGroupSubscription || {}).subscriptions || []
  const selectedRemaining = Math.max(0, selectedGroupSize - selectedMembers.length)

  useEffectAsync(
    () => {
      setTab(groupSubscriptionsLoading ? null : (groupSubscriptions[0] || {}).id || `new`)
      setGroupName(``)
      setEmailsToDistributeTo(``)
    },
    [ groupSubscriptionsLoading ],
  )

  const onTabChange = useCallback(
    (event, tab) => {
      setTab(tab)
      setGroupName(``)
      setEmailsToDistributeTo(``)
    },
    [],
  )
  const onTermChange = useCallback(({ target }) => setTerm(target.value), [])
  const onPlanChange = useCallback(({ target }) => setPlan(target.value), [])
  const onGroupSizeChange = useCallback(({ target }) => setGroupSize(target.value.replace(/[^0-9]/g, ``)), [])
  const [ goUpdateGroupSubscription, flushUpdateGroupSubscription ] = useThrottleCallback(update => update())
  const onGroupNameChange = useCallback(
    ({ target }) => {
      const name = target.value
      setGroupName(name)
      goUpdateGroupSubscription(async () => {
        await updateGroupSubscription({
          variables: {
            id: selectedGroupSubscription.id,
            input: {
              name: name.trim(),
            },
          },
          context,
        })
      })
    },
    [ goUpdateGroupSubscription, updateGroupSubscription, selectedGroupSubscription, context ],
  )
  const onEmailsToDistributeToChange = useCallback(({ target }) => setEmailsToDistributeTo(target.value), [])
  const onEmailsToDistributeToBlur = useCallback(
    () => {
      setEmailsToDistributeTo(
        [...new Set(
          getEmailsToDistributeTo()
            .split(/[\s,]+/g)
            .filter(possibleEmail => isValidEmail(possibleEmail))
            .slice(0, selectedRemaining)
        )].join('\n')
      )
    },
    [ selectedRemaining, getEmailsToDistributeTo ],
  )

  const selectedGroupPriceDiscount = (
    GROUP_PRICING_DISCOUNT
      .find(({ RANGE_START, RANGE_END }) => (
        selectedGroupSize >= RANGE_START
        && selectedGroupSize <= (RANGE_END || Infinity)
      ))
  )
  const discountPercentage = (selectedGroupPriceDiscount || {})[`PERCENTAGE_DISCOUNT_${selectedTerm}`] || 0
  const breakdownAmount = Math.round((PRICING[selectedPlan].MONTHLY * ((100 - discountPercentage) / 100)) * 100) / 100
  const totalAmount = breakdownAmount * (selectedTerm === `ANNUAL` ? 12 : 3) * selectedGroupSize

  const goCheckout = useCallback(
    async () => {

      setLoading(true)

      const { data: { createStripeCheckoutSession: { sessionId } } } = await createStripeCheckoutSession({
        variables: {
          successUrl: `${window.location.origin}/group-subscriptions`,
          cancelUrl: `${window.location.origin}/group-subscriptions`,
          term,
          plan,
          groupSize: selectedGroupSize,
        },
      })

      stripe.redirectToCheckout({ sessionId })

    },
    [ createStripeCheckoutSession, stripe, term, plan, selectedGroupSize ],
  )

  const sendInvites = useCallback(
    async () => {

      setLoading(true)

      await inviteGroupSubscriptionMembers({
        variables: {
          groupSubscriptionId: selectedGroupSubscription.id,
          input: {
            emails: getEmailsToDistributeTo().split(/\n/g),
          },
        },
        context,
      })

      setLoading(false)
      setInvitesSent(true)

    },
    [ inviteGroupSubscriptionMembers, selectedGroupSubscription, context, getEmailsToDistributeTo ],
  )

  const sendMoreInvites = useCallback(
    async () => {
      setEmailsToDistributeTo(``)
      setInvitesSent(false)
    },
    [],
  )

  const termRadios = useMemo(
    () => (
      [
        {
          value: `3MONTH`,
          label: i18n("3-Month Subscriptions"),
        },
        {
          value: `ANNUAL`,
          label: i18n("Annual Subscriptions"),
        },
      ].map(info => ({
        ...info,
        disabled: purchaseIsComplete,
      }))
    ),
    [ purchaseIsComplete ],
  )

  const planRadios = useMemo(
    () => (
      [
        // `MY`,
        `TOOLS`,
        `EQUIP`,
      ].map(plan => ({
        value: plan,
        label: <PlanImage src={getSubscriptionPlanImageSrc(plan)} />,
        disabled: purchaseIsComplete,
      }))
    ),
    [ purchaseIsComplete ],
  )

  return (
    <>

      <Header {...props} />

      <Container>
        <Content>

          <What className="dark-mode-exempt">
            <b>
              {i18n("Save up to 75% off subscriptions through group pricing.")}
            </b>
            {` `}
            {i18n("Great for academic institutions, ministry organizations, Bible study groups, churches...and even families.")}
            {` `}
            {i18n("Group subscriptions are purchased on an annual or 3-month basis and easy to facilitate.")}
          </What>

          <Explanation>
            {i18n("A group subscription is effectively a bulk purchase of annual or 3-month subscriptions (non-renewing) which you distribute to the Biblearc users (new and old) of your choosing.")}
          </Explanation>

          <Pricing>
            <PricingRow>
              <PricingRowNum />
              <PricingRowAnnualDiscountHeading>
                {i18n("Annual")}
              </PricingRowAnnualDiscountHeading>
              <PricingRow3MonthDiscountHeading>
                {i18n("3-Month")}
              </PricingRow3MonthDiscountHeading>
            </PricingRow>
            {GROUP_PRICING_DISCOUNT.map(({ RANGE_START, RANGE_END, PERCENTAGE_DISCOUNT_ANNUAL, PERCENTAGE_DISCOUNT_3MONTH }, idx) => (
              <PricingRow key={idx}>
                <PricingRowNum>
                  {
                    RANGE_END
                      ? (
                        i18n("{{range_start}}-{{range_end}} subscriptions", {
                          range_start: RANGE_START,
                          range_end: RANGE_END,
                        })
                      )
                      : (
                        i18n("{{range_start}}+ subscriptions", {
                          range_start: RANGE_START,
                        })
                      )
                  }
                </PricingRowNum>
                <PricingRowAnnualDiscount>
                  {i18n("{{percentage}}% off", {
                    percentage: PERCENTAGE_DISCOUNT_ANNUAL,
                  })}
                </PricingRowAnnualDiscount>
                <PricingRow3MonthDiscount>
                  {i18n("{{percentage}}% off", {
                    percentage: PERCENTAGE_DISCOUNT_3MONTH,
                  })}
                </PricingRow3MonthDiscount>
              </PricingRow>
            ))}
          </Pricing>

          {!isLoggedIn &&
            <StyledSignInButton
              label={i18n("Sign in to get started")}
            />
          }

          {!tab &&
            <LoadingContainer>
              <FadedLoading />
            </LoadingContainer>
          }

          {isLoggedIn && !!tab &&
            <>

              {groupSubscriptions.length > 0 &&
                <StyledTabs
                  value={tab}
                  onChange={onTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"  
                >
                  {groupSubscriptions.map(({ id, name }) => (
                    <Tab
                      key={id}
                      label={name}
                      value={id}
                    />
                  ))}
                  <Tab
                    label={i18n("Buy a New Group Subscription")}
                    value="new"
                  />
                </StyledTabs>
              }

              <Step $disabled={purchaseIsComplete}>
                {i18n("1.")}
                {` `}
                {i18n("Select group details")}
              </Step>

              <StyledCustomRadioGroup
                value={selectedPlan}
                onChange={onPlanChange}
                radios={planRadios}
              />

              <StyledCustomRadioGroup
                value={selectedTerm}
                onChange={onTermChange}
                radios={termRadios}
              />

              <StyledTextField
                label={i18n("Group size")}
                variant="outlined"
                value={selectedGroupSubscription ? selectedGroupSize : groupSize}
                disabled={purchaseIsComplete}
                onChange={onGroupSizeChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <Step $disabled={purchaseIsComplete}>
                {i18n("2.")}
                {` `}
                {i18n("Complete the purchase")}
              </Step>

              <Total>
                {i18n("Total: ${{amount}}", {  // eslint-disable-line no-template-curly-in-string
                  amount: totalAmount.toFixed(2),
                })}
              </Total>

              <TotalBreakdown>
                {i18n("${{amount}} per user per month", {  // eslint-disable-line no-template-curly-in-string
                  amount: breakdownAmount.toFixed(2),
                })}
              </TotalBreakdown>

              <CheckoutButtonContainer>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={goCheckout}
                  disabled={!online || purchaseIsComplete || selectedGroupSize < 2}
                  startIcon={purchaseIsComplete ? <CheckIcon /> : null}
                >
                  {
                    purchaseIsComplete
                      ? (
                        i18n("Purchased {{date}}", {
                          date: new Date(selectedGroupSubscription.createdAt).toLocaleDateString(getLocale()),
                        })
                      )
                      : i18n("Checkout")
                  }
                </Button>
              </CheckoutButtonContainer>

              <Step $disabled={!purchaseIsComplete}>
                {i18n("3.")}
                {` `}
                {i18n("Invite group members")}
              </Step>

              {purchaseIsComplete &&
                <Remain>
                  {
                    selectedRemaining === 1
                      ? i18n("1 slot remains.")
                      : (
                        i18n("{{number}} slots remain.", {
                          number: selectedRemaining,
                        })
                      )
                  }
                </Remain>
              }

              {purchaseIsComplete &&
                <GroupNameTextField
                  label={i18n("Group Name")}
                  value={selectedGroupName}
                  savedValue={(selectedGroupSubscription || {}).name}
                  onChange={onGroupNameChange}
                  onBlur={flushUpdateGroupSubscription}
                  disabled={!purchaseIsComplete}
                  variant="outlined"
                  size="small"
                />
              }

              {purchaseIsComplete && selectedGroupSize === selectedRemaining &&
                <JustPurchasedNote>
                  {i18n("Note: As the purchaser, you are NOT automatically given a group subscription slot. To get a slot, send an invitation to yourself.")}
                  {` `}
                  {i18n("(Click the FAQ button in the bottom-right corner for more important information.)")}
                </JustPurchasedNote>
              }

              {(!purchaseIsComplete || selectedRemaining > 0) &&
                <>

                  <EmailsToDistributeToTextField
                    label={i18n("Type or paste in emails")}
                    multiline
                    minRows={4}
                    value={emailsToDistributeTo}
                    onChange={onEmailsToDistributeToChange}
                    onBlur={onEmailsToDistributeToBlur}
                    disabled={!purchaseIsComplete || invitesSent}
                    variant="outlined"
                  />

                  <DistributeButtonContainer>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={sendInvites}
                      disabled={!purchaseIsComplete || invitesSent}
                      startIcon={invitesSent ? <CheckIcon /> : null}
                    >
                      {
                        invitesSent
                          ? i18n("Invitations sent")
                          : i18n("Send invitations")
                      }
                    </Button>
                    {invitesSent &&
                      <SendMoreButton
                        variant="outlined"
                        color="primary"
                        disableElevation
                        onClick={sendMoreInvites}
                      >
                        {i18n("Send more")}
                      </SendMoreButton>
                    }
                  </DistributeButtonContainer>

                  <DistributionExplanation $disabled={!purchaseIsComplete}>
                    {i18n("Each email will be sent a unique link that can be used to join the group, so long as there are still slots remaining.")}
                    {` `}
                    {i18n("Links expires one week after they are sent.")}
                  </DistributionExplanation>

                </>
              }


              {purchaseIsComplete &&
                <>
                  <GroupMembersHeading>
                    {i18n("Group Members")}
                  </GroupMembersHeading>
                  {selectedMembers.length === 0 &&
                    <NoGroupMembers>
                      {i18n("None")}
                    </NoGroupMembers>
                  }
                  {selectedMembers.map((subscription, idx) => (
                    <GroupMember key={idx}>
                      {subscription.user.name || i18n("Unknown name")}
                      {` `}
                      <GroupMemberEmail>
                        {subscription.user.email}
                      </GroupMemberEmail>
                      {` `}
                      {new Date(subscription.createdAt).toLocaleDateString(getLocale())}
                      {`–`}
                      {new Date(subscription.createdAt + (1000 * 60 * 60 * 24 * (selectedTerm === `ANNUAL` ? 365 : 92))).toLocaleDateString(getLocale())}
                    </GroupMember>
                  ))}
                </>
              }

            </>
          }

        </Content>
      </Container>

      <ContactUsFab
        title={i18n("Frequently Asked Questions")}
        icon={<FAQ>FAQ</FAQ>}
        to="/group-subscriptions-faq"
      />

      {loading && <FadedLoading />}

    </>
  )
}


export default memo(GroupSubscriptions)