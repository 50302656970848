import { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
import { useMeasure } from 'react-use'

import { getBoundedValue } from '../../utils/misc'

import StudyBibleDots from './StudyBibleDots'
import StudyBiblePage from './StudyBiblePage'
import LazyLoadPageViewer from '../common/LazyLoadPageViewer'
import FreehandContainer from '../common/FreehandContainer'
import VerticalInsetShadow from '../common/VerticalInsetShadow'

const Image = styled.img`
  vertical-align: middle;
  min-height: 75px;
`

const StyledLazyLoadPageViewer = styled(LazyLoadPageViewer)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: ${({ $height }) => $height || 0}px;

  .LazyLoadPageViewer-PageContainer {
    background: none;
    display: flex;
    flex-direction: column;
  }
`

const PagesContainer = styled.div`
  visibility: hidden;
  user-select: none;
  pointer-events: none;
  width: 100%;
  overflow: hidden;
`

const Pages = styled.div`
  flex: 1;
  display: flex;
  width: ${({ $numPages }) => $numPages * 100}%;
`

const StyledStudyBiblePage = styled(StudyBiblePage)`
  ${({ $dotsShown }) => !$dotsShown ? `` : `
    padding-bottom: 0;
  `}
`

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
`

const FreehandOuterContainer = styled.div`
  position: relative;
  overflow-y: auto;
`

const StudyBibleItemInsightContent = ({
  type,
  info,
  details=[],
  manualMode,
  fromThumbnail,
}) => {

  const { imageUrl, imageDimensions={} } = info || {}

  const [ pageKey, setPageKey ] = useState(0)
  const [ imageLoaded, setImageLoaded ] = useState(false)
  const [ ref, { height } ] = useMeasure()

  const setImageLoadedToTrue = useCallback(() => setImageLoaded(true), [])

  const goSetPageKey = useCallback(
    newPageKey => {
      setPageKey(
        getBoundedValue(newPageKey, { min: 0, max: details.length - 1 })
      )
    },
    [ setPageKey, details.length ],
  )

  const { svgStrokes } = (details[pageKey] || {}).info || {}

  return (
    <>

      <StyledLazyLoadPageViewer
        className="StudyBibleItemInsightContent-StyledLazyLoadPageViewer"
        pageKey={pageKey}
        page={
          <StyledStudyBiblePage
            detail={details[pageKey] || {}}
            addQuotes={[ `QUOTE`, `CREED` ].includes(type)}
            fromThumbnail={fromThumbnail}
            $dotsShown={details.length > 1}
          />
        }
        setPageKey={goSetPageKey}
        previousPageKey={pageKey - 1}
        nextPageKey={pageKey + 1}
        $height={height}
        swipeable={details.length > 1}
      />

      <PagesContainer>
        <Pages
          ref={ref}
          $numPages={details.length}
          className="StudyBibleItemInsightContent-Pages"
        >
          {details.map((detail, idx) => (
            <StyledStudyBiblePage
              key={idx}
              detail={detail}
              addQuotes={[ `QUOTE`, `CREED` ].includes(type)}
              $dotsShown={details.length > 1}
            />
          ))}
        </Pages>
      </PagesContainer>

      {details.length > 1 &&
        <StudyBibleDots
          numDots={details.length}
          pageKey={pageKey}
          setPageKey={setPageKey}
        />
      }

      <FreehandOuterContainer
        className="StudyBibleItemInsightContent-FreehandOuterContainer"
      >
        <FreehandContainer
          svgStrokes={svgStrokes}
          animate
          className="StudyBibleItemInsightContent-FreehandContainer"
          readyForAnimation={imageLoaded}
          manualMode={manualMode}
        >
          <ImageContainer
            className="StudyBibleItemInsightContent-ImageContainer"
          >
            <Image
              src={imageUrl}
              style={imageDimensions}
              onLoad={setImageLoadedToTrue}
            />
            <VerticalInsetShadow />
          </ImageContainer>
        </FreehandContainer>
      </FreehandOuterContainer>

    </>
  )
}

export default memo(StudyBibleItemInsightContent)