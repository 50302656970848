import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import DetailAccordion from '../../search/DetailAccordion'
import { cloneObj } from '../../../utils/misc'
import useStickyRefState from '../../../hooks/useStickyRefState'

const Container = styled.div`
  font-size: 13px;
  margin-top: -15px;

  p {
    margin: 7px 0;
    line-height: 16px;
  }
`

const ExampleContainer = styled.div`
  background: ${({ theme }) => theme.palette.grey[100]};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 3px;
  margin: 7px 0;
`

const Example = styled.div`
  margin-bottom: 9px;
  line-height: 16px;
  font-weight: 300;

  &:last-child {
    margin: 0;
  }
`

const Heading = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.secondary.main};
  margin: 15px 0 7px;
`

const Subheading = styled.div`
  font-style: italic;
  margin: 10px 0 7px;
`

const SubheadingPoint = styled.div`
  margin: 3px 0 3px 7px;
`


const StyledDetailAccordion = styled(DetailAccordion)`
  background: none;

  && {
    margin: 0;
  }

  .DetailAccordion-StyledAccordionSummary {
    margin: 0 2px;
    font-weight: 500;
    font-size: 13px;
  }

  .MuiAccordionDetails-root {
    padding: 0 9px 8px;
  }

`

const TaggerRules = ({
  hebrewOnly,
  greekOnly,
  ...otherProps
}) => {

  const [ expanded, setExpanded ] = useStickyRefState({ id: `TaggerRules:expanded`, defaultValue: [] })

  const toggleExpanded = idx => () => {
    const newExpanded = cloneObj(expanded)
    newExpanded[idx] = !newExpanded[idx]
    setExpanded(newExpanded)
  }
  
  const showHebrew = !greekOnly
  const showGreek = !hebrewOnly

  return (
    <Container {...otherProps}>

      <Heading>
        {i18n("1.")}
        {` `}
        {i18n("Articles")}
      </Heading>

      <Subheading>
        {i18n("Definite article")}
      </Subheading>

      <p>
        {i18n("The definite article in the original should be tagged to all words in the translation that it is conveying.")}
        {` `}
        {i18n("When the definite article is not translated to anything or is absent from the original, it should be left untagged.")}
      </p>

      <ExampleContainer>
        <StyledDetailAccordion
          expanded={!!expanded[0]}
          onChange={toggleExpanded(0)}
          summary={i18n("Examples")}
          details={
            <>
              {showHebrew &&
                <>
                  <Example>
                    {i18n("E.g. For “פְּנֵ֥י הַ/מָּֽיִם” in Genesis 1:2, “פְּנֵ֥י” should be tagged to “face of” (NOT: “the face of”), “הַ” should be tagged to “the” (in the phrase “the waters”) and “מָּֽיִם” should be tagged to “waters.”")}
                  </Example>
                  <Example>
                    {i18n("E.g. In Genesis 12:7, we find “הַ/נִּרְאֶ֥ה אֵלָֽי/ו” translated to “who had appeared to him.” In this case, the definite article “הַ” should be tagged to “who.”")}
                  </Example>
                  <Example>
                    {i18n("E.g. In Deuteronomy 24:19, we find “לַ/גֵּ֛ר לַ/יָּת֥וֹם וְ/לָ/אַלְמָנָ֖ה” translated to “for the sojourner, the fatherless, and the widow.” Here, the first ל preposition should be tagged to “for the” (including “the”) since its vowel indicates an implicit definite article. The next two ל prepositions, on the other hand, should be left untagged. For, while they too have implicit definite articles, the prepositions themselves are untranslated and we don’t want them tagged only to “the.”")}
                  </Example>
                </>
              }
              {showGreek &&
                <>
                  <Example>
                    {i18n("E.g. For “τοὺς ἀδελφοὺς αὐτοῦ” in Matthew 1:11, “ἀδελφοὺς” should be tagged to “brothers,” “αὐτοῦ” should be tagged to “his,” and “τοὺς” should be left untagged.")}
                  </Example>
                  <Example>
                    {i18n("E.g. In Matthew 7:21, “ὁ ποιῶν” is translated to “the one who does.” In this case, the definite article “ὁ” should be tagged to “the one who” and “ποιῶν” should be tagged to just “does.”")}
                  </Example>
                  <Example>
                    {i18n("E.g. For “τοῖς οὖσιν,” translated “who are” in Philippians 1:1, the article ”τοῖς” should be tagged to “who” and “οὖσιν” should be tagged to “are.”")}
                  </Example>
                </>
              }
            </>
          }
        />
      </ExampleContainer>

      <p>
        {i18n("The only exception is when a proper noun with the definite article is translated to a proper noun without, or vice versa.")}
        {` `}
        {i18n("In this case, include the definite article within the tag.")}
      </p>

      <ExampleContainer>
        <StyledDetailAccordion
          expanded={!!expanded[1]}
          onChange={toggleExpanded(1)}
          summary={i18n("Examples")}
          details={
            <>
              {showHebrew &&
                <>
                  <Example>
                    {i18n("E.g. “הַ/גִּלְעָ֔ד” (with the definite article) in Numbers 32:40 should be tagged to “Gilead.”")}
                  </Example>
                </>
              }
              {showGreek &&
                <>
                  <Example>
                    {i18n("E.g. “τὸν Θεόν” (with the definite article) in John 1:1 should be tagged to “God.”")}
                  </Example>
                  <Example>
                    {i18n("E.g. In Matthew 5:18, we find “ὁ οὐρανὸς καὶ ἡ γῆ” translated to “heaven and earth.” You should include the greek article in your tags of both “heaven” and “earth” since these are definite locations effectively acting like proper nouns.")}
                  </Example>
                </>
              }
            </>
          }
        />
      </ExampleContainer>

      <Subheading>
        {i18n("Indefinite article")}
      </Subheading>

      <p>
        {i18n("The indefinite article in a translation should almost never be tagged, since neither Hebrew nor Greek utilize an explicit indefinite article.")}
      </p>

      <ExampleContainer>
        <StyledDetailAccordion
          expanded={!!expanded[2]}
          onChange={toggleExpanded(2)}
          summary={i18n("Examples")}
          details={
            <>
              {showHebrew &&
                <Example>
                  {i18n("E.g. “גַּן” in Genesis 2:8 should only be tagged to “garden,” leaving the preceding “a” untagged.")}
                </Example>
              }
              {showGreek &&
                <>
                  <Example>
                    {i18n("E.g. “ἄνθρωπος” in John 1:6 should only be tagged to “man,” leaving the preceding “a” untagged.")}
                  </Example>
                  <Example>
                    {i18n("E.g. In Acts 3:2 we find an exception. There, the indefinite article “a” is a translation of the specific word “τις” and so should be tagged to it.")}
                  </Example>
                </>
              }
            </>
          }
        />
      </ExampleContainer>

      <Heading>
        {i18n("2.")}
        {` `}
        {i18n("Words Added in the Translation")}
      </Heading>

      <Subheading>
        {i18n("Do tag...")}
      </Subheading>

      <SubheadingPoint>
        {i18n("a.")}
        {` `}
        {i18n("Pronouns designated by a verb’s inflection or implied by its usage")}
      </SubheadingPoint>

      <SubheadingPoint>
        {i18n("b.")}
        {` `}
        {i18n("Helping verbs")}
      </SubheadingPoint>

      <SubheadingPoint>
        {i18n("c.")}
        {` `}
        {i18n("Genitive or dative helper words")}
      </SubheadingPoint>

      <SubheadingPoint>
        {i18n("d.")}
        {` `}
        {i18n("Translations you disagree with")}
      </SubheadingPoint>

      <ExampleContainer>
        <StyledDetailAccordion
          expanded={!!expanded[3]}
          onChange={toggleExpanded(3)}
          summary={i18n("Examples")}
          details={
            <>
              {showHebrew &&
                <>
                  <Example>
                    {i18n("E.g. In Genesis 2:17, “לֹ֥א תֹאכַ֖ל” is translated “you shall not eat.” In this case, “לֹ֨א” should be tagged to “not” and “תֹאכַ֖ל” should be tagged to “you shall ... eat.”")}
                  </Example>
                  <Example>
                    {i18n("E.g. “ר֣וּחַ” in Genesis 1:2 (from the phrase “ר֣וּחַ אֱלֹהִ֔ים”) should be tagged to “Spirit of.”")}
                  </Example>
                </>
              }
              {showGreek &&
                <>
                  <Example>
                    {i18n("E.g. In John 3:12, “οὐ πιστεύετε” is translated “you do not believe.” In this case, “οὐ” should be tagged to “not” and “πιστεύετε” should be tagged to “you do ... believe.”")}
                  </Example>
                  <Example>
                    {i18n("E.g. In Matthew 7:11, “ἐξήλθατε” should be tagged to “did you go out.”")}
                  </Example>
                  <Example>
                    {i18n("E.g. “ἀνθρώπων” in John 1:4 should be tagged to “of men.”")}
                  </Example>
                  <Example>
                    {i18n("E.g. In John 1:4, “τοῦ ἱεροῦ” is translated “of the temple.” In this case, include the genitive helper word in the tag of the noun, such that “τοῦ” is tagged to “the” and “ἱεροῦ” is tagged to “of ... temple.”")}
                  </Example>
                  <Example>
                    {i18n("E.g. In Acts 15:11, the infinitive “σωθῆναι” should be tagged to ”we will be saved” since it implies that entire phrase in this verse.")}
                  </Example>
                </>
              }
            </>
          }
        />
      </ExampleContainer>

      <Subheading>
        {i18n("Do NOT tag...")}
      </Subheading>

      <SubheadingPoint>
        {i18n("a.")}
        {` `}
        {i18n("Words merely added for clarity and not translated from any particular word in the original")}
      </SubheadingPoint>

      <SubheadingPoint>
        {i18n("b.")}
        {` `}
        {i18n("Pronouns translated to proper nouns, or vice versa")}
      </SubheadingPoint>

      {/* <SubheadingPoint>
        {i18n("c.")}
        {` `}
        {i18n("Translation words indicated by grammatical constructions NOT found in the specific original word(s) you are currently tagging")}
      </SubheadingPoint> */}

      <ExampleContainer>
        <StyledDetailAccordion
          expanded={!!expanded[4]}
          onChange={toggleExpanded(4)}
          summary={i18n("Examples")}
          details={
            <>
              {showHebrew &&
                <>
                  <Example>
                    {i18n("E.g. In Genesis 30:29, some translations read “Jacob said” even though the original only has “וַ/יֹּ֣אמֶר.” In such a case, “Jacob” should be left untagged.")}
                  </Example>
                  <Example>
                    {i18n("E.g. In Genesis 29:23, some translations read “to Jacob” even though the original only has “אֵלָ֑י/ו.” In such a case, “Jacob” and “ו” should be left untagged.")}
                  </Example>
                  {/* <Example>
                    {i18n("E.g. ")}
                  </Example> */}
                </>
              }
              {showGreek &&
                <>
                  <Example>
                    {i18n("E.g. In Matthew 8:3, some translations read “Jesus stretched out” even though the original only has “ἐκτείνας.” In such a case, “Jesus” should be left untagged.")}
                  </Example>
                  {/* <Example>
                    {i18n("E.g. pronoun -> proper noun")}
                  </Example> */}
                  {/* <Example>
                    {i18n("E.g. ")}
                  </Example> */}
                </>
              }
            </>
          }
        />
      </ExampleContainer>

      <Heading>
        {i18n("3.")}
        {` `}
        {i18n("Untranslated Original Language Words")}
      </Heading>

      <p>
        {i18n("Do NOT tag words left untranslated, no matter the reason.")}
      </p>

      {showHebrew &&
        <>
          <Subheading>
            {i18n("This includes...")}
          </Subheading>
          <SubheadingPoint>
            {i18n("a.")}
            {` `}
            {i18n("Words only repeated in the original language")}
          </SubheadingPoint>
          <SubheadingPoint>
            {i18n("b.")}
            {` `}
            {i18n("Paragogic ה or ן")}
          </SubheadingPoint>
          <SubheadingPoint>
            {i18n("c.")}
            {` `}
            {i18n("The definite direct object marker אֵת")}
          </SubheadingPoint>
        </>
      }

      <ExampleContainer>
        <StyledDetailAccordion
          expanded={!!expanded[5]}
          onChange={toggleExpanded(5)}
          summary={i18n("Examples")}
          details={
            <>
              {showHebrew &&
                <>
                  <Example>
                    {i18n("E.g. Some versions do not translate “לֵ/אמֹֽר” in Genesis 8:15 since it is redundant. In such cases, leave the original word(s) untagged.")}
                  </Example>
                  <Example>
                    {i18n("E.g. In Genesis 9:12, we find “בֵּינִ/י֙ וּ/בֵ֣ינֵי/כֶ֔ם וּ/בֵ֛ין כָּל־נֶ֥פֶשׁ חַיָּ֖ה” translated to “between me and you and every living creature.” The second and third occurrences of “בֵּין” should be left untagged.")}
                  </Example>
                  <Example>
                    {i18n("E.g. For “תְּמֻתֽוּ/ן” in Genesis 3:4, only “תְּמֻתֽוּ” should be tagged to “you will die.” The “ן” should be left untagged.")}
                  </Example>
                  <Example>
                    {i18n("E.g. For “בָּרָ֣א אֹת֑/וֹ” in Genesis 1:27, only “וֹ” should be tagged to “him” while “אֹת֑” should be left untagged.")}
                  </Example>
                </>
              }
              {showGreek &&
                <>
                  <Example>
                    {i18n("E.g. In Matthew 26:48, we find “ὃν ἂν φιλήσω” and the translation “The one I will kiss.” Here, “ἂν” should be left untagged since no sense from it is drawn into the translation.")}
                  </Example>
                  <Example>
                    {i18n("E.g. However, in Matthew 20:27, the entire phrase “ὃς ἂν” should be tagged to “whoever,” since “ἂν” does contribute to the sense captured by the translation.")}
                  </Example>
                </>
              }
            </>
          }
        />
      </ExampleContainer>

      <Heading>
        {i18n("4.")}
        {` `}
        {i18n("Idioms and Word Combos")}
      </Heading>

      <p>
        {i18n("Include an entire idiom or word combo in a single tag only when it is not possible to tag the words individually.")}
      </p>

      <ExampleContainer>
        <StyledDetailAccordion
          expanded={!!expanded[6]}
          onChange={toggleExpanded(6)}
          summary={i18n("Examples")}
          details={
            <>
              {showHebrew &&
                <>
                  <Example>
                    {i18n("E.g. The entire phrase “מ֥וֹת תָּמֽוּת” in Genesis 2:17 should be tagged to “shall surely die” since it would be incorrect to say that “מ֥וֹת” is translated “surely” (even though that is the effect of the infinitive absolute in this context).")}
                  </Example>
                  <Example>
                    {i18n("E.g. In Genesis 9:14, we find “בְּ/עַֽנְנִ֥/י עָנָ֖ן” translated to “When I bring clouds.” “בְּ” should be tagged to “when” and “י” should be tagged to “I.” However, the entire phrase “עַֽנְנִ֥– עָנָ֖ן” should be tagged to “bring clouds” since the verb alone does not mean “to bring” but rather “to cloud.”")}
                  </Example>
                </>
              }
              {showGreek &&
                <>
                  <Example>
                    {i18n("E.g. The entire phrase “ἐν γαστρὶ ἔχουσα” (lit. “in womb having”) in Matthew 1:18 should be tagged to the phrase “to be with child.”")}
                  </Example>
                  <Example>
                    {i18n("E.g. In Luke 22:15, we find “ἐπιθυμίᾳ ἐπεθύμησα” translated to “I have earnestly desired.” The entire phrases should be tagged to each other since “ἐπιθυμίᾳ” alone does not mean “earnestly” but rather “with desire.”")}
                  </Example>
                  <Example>
                    {i18n("E.g. Tag “Κυρίου Ἰησοῦ” of Acts 15:11 to “of...Lord Jesus” since the genitive sense translated “of” connects to both words equally.")}
                  </Example>
                  <Example>
                    {i18n("E.g. In Philippians 1:10, the words “εἰς τὸ” together mean “so that” and thus should be tagged as a combo.")}
                  </Example>
                </>
              }
            </>
          }
        />
      </ExampleContainer>

      <p>
        {i18n("Only combine a verb and preposition within a tag if the presence of the preposition changes the verb’s meaning.")}
      </p>

      <ExampleContainer>
        <StyledDetailAccordion
          expanded={!!expanded[7]}
          onChange={toggleExpanded(7)}
          summary={i18n("Examples")}
          details={
            <>
              {showHebrew &&
                <>
                  <Example>
                    {i18n("E.g. In Genesis 9:3, we find “נָתַ֨תִּי לָ/כֶ֜ם” translated to “I gave you.” Only “נָתַ֨תִּי” should be tagged to “I gave” since that is the meaning of this verb with or without the “לְ.” Thus, “לְ” should be left untagged while “כֶ֜ם” should be tagged to “you.”")}
                  </Example>
                  <Example>
                    {i18n("E.g. In Genesis 2:24, we find “וְ/הָי֖וּ לְ/בָשָׂ֥ר אֶחָֽד” translated to “and they shall become one flesh.” In this case, “הָי֖וּ” and “לְ” should be combined together and then tagged to “become” since “הָיָה” only carries that meaning when paired with the preposition “לְ”.")}
                  </Example>
                </>
              }
              {showGreek &&
                <>
                  <Example>
                    {i18n("E.g. In Mark 3:20, we find “ἔρχεται εἰς οἶκον” translated to “he went home.” Only “ἔρχεται” should be tagged to “he went” since that is the meaning of this verb with or without the “εἰς.” Thus, “εἰς” should be left untagged while “οἶκον” should be tagged to “home.”")}
                  </Example>
                  <Example>
                    {i18n("E.g. Likewise, in Matthew 6:6, only the verb “εἴσελθε” from the phrase “εἴσελθε εἰς” should be tagged to “go into.” The “εἰς” does not alter the verb’s meaning and so should be left untagged.")}
                  </Example>
                  <Example>
                    {i18n("E.g. In Matthew 12:9, we find “ἦλθεν εἰς” translated to “entered.” In this case, “ἦλθεν” and “εἰς” should be combined together and then tagged to “entered” since “ἦλθεν” only carries that meaning when paired with the preposition “εἰς.”")}
                  </Example>
                </>
              }
            </>
          }
        />
      </ExampleContainer>

    </Container>
  )
}

export default memo(TaggerRules)