const keyDownSpace = async params => {

  const {
    event,
    manualMode,
    doManualAdvance,
  } = params

  if(!manualMode) return

  event.preventDefault()
  doManualAdvance()

}

export default keyDownSpace