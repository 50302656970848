import { useMemo, useRef } from 'react'

import useGoUpdateHighlights from './useGoUpdateHighlights'
import useInstanceValuesCallback from './useInstanceValuesCallback'

const useGoUpdateHighlight = ({
  highlight,
  undoRedoStack,
}={}) => {

  const { id } = highlight || {}
  const upToDateHighlight = useRef({})
  const getUpToDateHighlight = useInstanceValuesCallback(() => (id && upToDateHighlight.current[id]) || highlight)

  const [ goUpdate, goDelete ] = useGoUpdateHighlights({
    undoRedoStack,
  })

  const toReturn = useMemo(
    () => ([
      (updateObj={}, options) => {
        const updatedHighlight = goUpdate(
          [ updateObj ],
          highlight && [ getUpToDateHighlight() ],
          options,
        )[0]
        upToDateHighlight.current = {
          [updatedHighlight.id]: updatedHighlight,
        }
        return updatedHighlight
      },
      ...(highlight ? [ () => goDelete([ getUpToDateHighlight() ]) ] : []),
    ]),
    [ highlight, getUpToDateHighlight, goUpdate, goDelete ],
  )

  return toReturn
}

export default useGoUpdateHighlight