import { memo, useContext, useMemo, useState } from 'react'
import { i18n } from 'inline-i18n'
// import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MenuList from '@material-ui/core/MenuList'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import SubjectIcon from '@material-ui/icons/Subject'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import DeleteIcon from '@material-ui/icons/Delete'

import useModalAnchor from '../../hooks/useModalAnchor'
import useDataQuery from '../../hooks/useDataQuery'
import useAppSize from '../../hooks/useAppSize'
import useSimpleToggle from '../../hooks/useSimpleToggle'
import useInstanceValuesCallback from '../../hooks/useInstanceValuesCallback'
import useMapLayers from '../../hooks/useMapLayers'
import useDelay from '../../hooks/useDelay'
import { cutOffString, getPrimaryName, getTextFromLexicalData, preventDefaultEvent } from '../../utils/misc'
import { LoggedInUserContext } from '../../context/LoggedInUser'

import OptionsPopover from './OptionsPopover'
import FadedLoading from './FadedLoading'
import MainMenuMapNote from './MainMenuMapNote'

import accountSettingsQuery from '../../graphql/queries/accountSettings'

const FILL_SCREEN_MAX_WIDTH = 499

const Content = styled.div`
  max-height: 600px;
  display: flex;
  flex-direction: column;
  width: min(calc(100vw - 40px), 330px);

  ${({ $isMini }) => !$isMini ? `` : `
    max-height: none;
    height: calc(100vh - 10px);
    width: calc(100vw - 10px);
  `}
`

const StyledSubjectIcon = styled(SubjectIcon)`
  font-size: 21px;
  margin: 2px 9px 1px 3px;
`

const MapNotesHeader = styled.div`
  background: ${({ theme }) => theme.palette.grey[200]};
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 10px 15px 10px 22px;
`

const HeaderText = styled.div`
  flex: 1;
`

const ItemContent = styled.span`
  flex: 1;
  min-width: 240px;
  font-weight: 300;
  white-space: nowrap;
  display: flex;
  align-items: center;
  max-width: min(calc(100vw - 40px), 300px);

  ${({ $isMini }) => !$isMini ? `` : `
    width: calc(100vw - 10px);
    max-width: none;
  `}
`

const ItemText = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.05;
  padding: 1px 0 2px;
`

const StyledMenuList = styled(MenuList)`
  overflow: auto;
  padding-bottom: 10px;
  min-height: 115px;
`

const StyledIconButton = styled(IconButton)`
  padding: 9px;
  
  .MuiSvgIcon-root {
    font-size: 22px;
  }
`

const None = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  text-align: center;
  font-weight: 300;
  margin: 30px 0 25px;
`

const ToCreate = styled.div`
  text-align: center;
  margin: -10px 30px 30px;
`

const DeleteIconButton = styled(StyledIconButton)`
  margin: 0 -5px 0 0;
  ${({ $deleteMode, theme }) => !$deleteMode ? `` : `
    color: ${theme.palette.tertiary.main};
  `}
`

const StyledTextField = styled(TextField)`
  flex: 1;
`

const TopLine = styled.div`
  margin: 20px 15px 0;
  display: flex;
  align-items: center;
  gap: 5px;
`

const MainMenuMapNotes = ({
  toggleDrawerOpen,
}) => {

  const user = useContext(LoggedInUserContext)
  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const { width } = useAppSize()
  const [ searchStr, setSearchStr ] = useState(``)
  const [ deleteMode, toggleDeleteMode ] = useSimpleToggle()
  const hasAnchorElAfterDelay = useDelay(!!anchorEl)
  const isOpen = !!(anchorEl || hasAnchorElAfterDelay)

  const layer = `BIBLE`
  const stage = `PUBLISHED`
  const { getMapLayerState } = useMapLayers({ layer, stage })
  const [ mapLayer ] = getMapLayerState({ layer, stage })

  const isMini = width <= FILL_SCREEN_MAX_WIDTH

  const { accountSettings } = useDataQuery({
    accountSettingsQuery,
    variables: {
      idPrefix: `${(user || {}).id}:map-note-`
    },
    fetchPolicy: 'network-only',  // doesn't actually go through the network, but forces fresh dexie query
    skip: !isOpen,
  })

  const alteredMapNotesAccountSettings = useMemo(
    () => {
      const searchStrParts = searchStr.replace(/\s\s+/g, ` `).trim().toLowerCase().split(` `).filter(Boolean)
      return (
        (accountSettings || [])
          .map(({ id, value }) => {

            const [ mapItemTypePart, mapItemId ] = id.split(`:`)[1].split(`-`).slice(2)
            const mapItemType = {
              jrney: `journey`,
              prsn: `person`,
            }[mapItemTypePart] || mapItemTypePart
            const noteText = cutOffString(getTextFromLexicalData((value || {}).note), 80)

            let data = { ...(mapLayer.data || {}) }
            if(mapItemType === `event`) {
              data.events = data.places.map(({ events }) => events).flat()
            }
            const mapItemName = getPrimaryName(data[`${mapItemType}s`].find(({ id }) => id === mapItemId))

            const text = `${mapItemName} ${noteText}`.toLowerCase()

            if(!noteText) return null

            if(searchStrParts.length > 0) {
              if(!searchStrParts.every(searchStrPart => text.includes(searchStrPart))) return null
            }

            return {
              id,
              mapItemType,
              mapItemId,
              mapItemName,
              noteText,
              updatedAt: (value || {}).updatedAt,
            }

          })
          .filter(Boolean)
          .sort((a,b) => (b.updatedAt || 0) - (a.updatedAt || 0))
      )
    },
    [ accountSettings, mapLayer.data, searchStr ],
  )

  const onChange = useInstanceValuesCallback(({ target }) => setSearchStr(target.value))

  const onClick = useInstanceValuesCallback(
    event => {
      preventDefaultEvent(event)
      openModal(event)
    },
  )

  return (
    <>

      <ListItem
        button
        onClick={onClick}
        className="MainMenuMapNotes-ListItem"
      >
        <ListItemText primary={
          <ItemContent>
            <StyledSubjectIcon />
            <ItemText>
              {i18n("Map Notes")}
            </ItemText>
          </ItemContent>
        } />
      </ListItem>

      <OptionsPopover
        anchorEl={anchorEl}
        onClose={closeModal}
        hideArrow
      >
        <Content $isMini={isMini}>

          {isMini &&
            <MapNotesHeader>
              <HeaderText>
                {i18n("Map Notes")}
              </HeaderText>
              <StyledIconButton onClick={closeModal}>
                <CloseIcon />
              </StyledIconButton>
            </MapNotesHeader>
          }

          <TopLine>

            <StyledTextField
              value={searchStr}
              variant="outlined"
              onChange={onChange}
              size="small"
              placeholder={i18n("Search my map notes")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            <Tooltip
              title={
                deleteMode
                  ? i18n("Toggle off delete mode")
                  : i18n("Toggle on delete mode")
              }
            >
              <DeleteIconButton
                $deleteMode={deleteMode}
                onClick={toggleDeleteMode}
              >
                <DeleteIcon />
              </DeleteIconButton>
            </Tooltip>
          </TopLine>

          <StyledMenuList>

            {alteredMapNotesAccountSettings.map(info => (
              <MainMenuMapNote
                key={`${info.mapItemType}-${info.mapItemId}`}
                {...info}
                toggleDrawerOpen={toggleDrawerOpen}
                deleteMode={deleteMode}
              />
            ))}

            {!!accountSettings && alteredMapNotesAccountSettings.length === 0 &&
              <>
                <None>
                  {i18n("None found")}
                </None>
                {!searchStr &&
                  <ToCreate>
                    {i18n("Attach notes to map places by clicking the notes icon in the top-right corner of that place’s information box.")}
                  </ToCreate>
                }
              </>
            }

            {!accountSettings && <FadedLoading size={20} />}

          </StyledMenuList>

        </Content>
      </OptionsPopover>

    </>
  )
}

export default memo(MainMenuMapNotes)