import { memo } from 'react'
import { i18n } from 'inline-i18n'
// import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'

import BibleMapInfoName from './BibleMapInfoName'
import BibleMapInfoEvent from './BibleMapInfoEvent'
import BibleMapInfoBody from './BibleMapInfoBody'
import BibleMapNote from './BibleMapNote'

const About = styled.div`
`

const AboutContent = styled.div`
  font-weight: 300;
  color: ${({ theme }) => theme.palette.grey[700]};
`

const Events = styled.div`
`

const Heading = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const EventItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 5px 0;
`

const NoInfo = styled.div`
  font-weight: 200;
`

const BibleMapPlaceInfo = ({
  place,
  setSelectedJourney,
  setSelectedPerson,
  journeys,
  persons,
  expandedEventId,
  setExpandedEventId,
  mapNotePlace,
  setMapNotePlace,
  toggleMyPlanPromoDialogOpen,
}) => {

  return (
    <BibleMapInfoBody
      className="BibleMapPlaceInfo-BibleMapInfoBody"
    >

      {place.names.map(({ id, ...nameInfo }, idx) => (
        <BibleMapInfoName
          key={id}
          type="place"
          // isPrimary={idx === 0}
          skipLabel={idx === 0}
          {...nameInfo}
        />
      ))}

      {!!place.about &&
        <About>
          <Heading>
            {i18n("About")}
          </Heading>
          <AboutContent>
            {place.about}
          </AboutContent>
        </About>
      }

      {!place.about && place.events.length === 0 &&
        <NoInfo>
          {i18n("No further information available.")}
        </NoInfo>
      }

      <BibleMapNote
        key={place.id}
        mapNoteItem={mapNotePlace}
        setMapNote={setMapNotePlace}
      />

      {place.events.length > 0 &&
        <Events>
          <Heading>
            {i18n("Events")}
          </Heading>
          <EventItems>
            {place.events.map(event => (
              <BibleMapInfoEvent
                key={event.id}
                {...event}
                place={place}
                explanded={expandedEventId === event.id}
                setExpandedEventId={setExpandedEventId}
                setSelectedJourney={setSelectedJourney}
                setSelectedPerson={setSelectedPerson}
                journeys={journeys}
                persons={persons}
                toggleMyPlanPromoDialogOpen={toggleMyPlanPromoDialogOpen}
              />
            ))}
          </EventItems>
        </Events>
      }

    </BibleMapInfoBody>
  )
}

export default memo(BibleMapPlaceInfo)