import React, { memo, useCallback, useContext } from 'react'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SubjectIcon from '@material-ui/icons/Subject'
import Button from '@material-ui/core/Button'

import { getDateAsString, getPrimaryName } from '../../../utils/misc'
import useInstanceValuesCallback from '../../../hooks/useInstanceValuesCallback'
import useAccountSetting from '../../../hooks/useAccountSetting'
import { LoggedInUserContext } from '../../../context/LoggedInUser'

import BibleMapInfoName from './BibleMapInfoName'
import BibleMapInfoEventPerson from './BibleMapInfoEventPerson'
import ContainerWithPassagePopper from '../../passage/ContainerWithPassagePopper'
import BibleMapInfoEventReferences from './BibleMapInfoEventReferences'
import BibleMapNote from './BibleMapNote'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Chip = styled.div`
  min-width: 0;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: ${({ $color, theme }) => $color || theme.palette.mapColors.event};
  border-radius: 12px;
  color: white;
  padding: 5px 10px;
  line-height: 1;
  user-select: none;
  pointer-events: all;
`

const ChipDate = styled.span`
  font-size: 11px;
  opacity: .5;
  margin-left: 1px;
`

const ChipName = styled.span`
  font-size: 13px;
  opacity: .9;
`

const ExpandedSection = styled.div`
  align-self: stretch;
  display: ${({ $expanded }) => $expanded ? `block` : `none`};
  padding: 5px 10px;
  font-size: 13px;
`

const DateInfo = styled.div`
  margin-top: 5px;
`

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`

const Date = styled.div`
  font-weight: 400;
`

const Also = styled.div`
  font-weight: 300;
  color: ${({ theme }) => theme.palette.grey[700]};
`

const AlsoDate = styled.div`
  display: inline-block;
  font-weight: 400;
  color: black;
`

const DateUncertain = styled.div`
  background-color: ${({ theme }) => theme.palette.grey[300]};
  border-radius: 10px;
  font-size: 9.5px;
  padding: 1px 5px;
  margin-left: 5px;
`

const DateHighlyUncertain = styled(DateUncertain)`
  background-color: ${({ theme }) => theme.palette.grey[600]};
  color: white;
`

const DateNotes = styled.div`
  font-weight: 300;
  color: ${({ theme }) => theme.palette.grey[700]};
  font-style: italic;
`

const About = styled.div`
`

const Heading = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  margin-top: 10px;
`

const AboutContent = styled.div`
  font-weight: 300;
  color: ${({ theme }) => theme.palette.grey[700]};
`

const Journey = styled.div`
  padding-top: 5px;
  font-size: 12.5px;
  font-weight: 300;
`

const People = styled.div`
`

const References = styled.div`
`

const JourneyName = styled.span`
  color: ${({ $color }) => `${$color}bf`};
  filter: brightness(70%);
  text-decoration: underline;
  cursor: pointer;
  transition: opacity .15s ease-in-out;

  &:hover {
    opacity: .7;
  }

`

const Location = styled.div`
  padding-top: 5px;
  font-size: 12.5px;
  font-weight: 300;
`

const LocationName = styled.span`
  color: ${({ theme }) => theme.palette.mapColors.selectedPlace};
  text-decoration: underline;
  cursor: pointer;
  transition: opacity .15s ease-in-out;

  &:hover {
    opacity: .7;
  }
`

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  margin-left: 5px;
  font-size: 18px;
  opacity: .5;
  transition: transform .2s ease-in-out, opacity .15s ease-in-out;
  transform: rotate(${({ $explanded }) => $explanded ? 180 : 0}deg);
`

const BibleMapInfoEventPersons = styled.div`
`

const ChipButton = styled.div`
  min-width: 0;
  max-width: 100%;
  display: flex;
  align-items: center;
  transition: opacity .15s ease-in-out;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      opacity: .85;

      .MuiSvgIcon-root {
        opacity: 1;
      }
    }
  }
`

const StyledContainerWithPassagePopper = styled(ContainerWithPassagePopper)`
  .options-popper-clear-cover {
    position: fixed;
  }
`

const StyledButton = styled(Button)`
  font-size: 10px;
  font-weight: bold;
  padding: 4px 8px;
  margin: 5px -6px -2px;

  .MuiButton-startIcon {
    margin-right: 4px;
  }

  .MuiSvgIcon-root {
    font-size: 14px;
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`

const StyledBibleMapNote = styled(BibleMapNote)`
  margin: 10px 0 -10px;

  .BibleMapNote-Heading {
    font-size: 10px;
  }
`

const BibleMapInfoEvent = ({
  id,
  names,
  dates,
  journeyId,
  personIds,
  passages,
  about,
  color,
  journeys,
  persons,
  setSelectedJourney,
  setSelectedPerson,
  explanded,
  setExpandedEventId,
  includePlace,
  place,
  setSelectedPlace,
  toggleMyPlanPromoDialogOpen,
}) => {

  const { hasMyPlan } = useContext(LoggedInUserContext) || {}
  const [ mapNoteEvent, setMapNoteEvent ] = useAccountSetting(`map-note-event-${id}`, {})

  const journey = journeys.find(({ id }) => id === journeyId)

  const toggleExpand = useCallback(() => setExpandedEventId(explanded ? null : id), [ explanded, id, setExpandedEventId ])

  const selectJourney = useCallback(
    () => {
      setSelectedPerson()
      setSelectedJourney(journey)
    },
    [ setSelectedPerson, setSelectedJourney, journey ],
  )

  const selectPlace = useCallback(
    () => {
      setSelectedJourney()
      setSelectedPerson()
      setSelectedPlace(place)
    },
    [ setSelectedJourney, setSelectedPerson, setSelectedPlace, place ],
  )

  const createMapNote = useInstanceValuesCallback(
    () => {
      if(hasMyPlan) {
        setMapNoteEvent({ ...mapNoteEvent, note: `` })
      } else {
        toggleMyPlanPromoDialogOpen({ force: true })
      }
    }
  )

  return (
    <Container>

      <ChipButton
        className="BibleMapInfoEvent-ChipButton"
        onClick={toggleExpand}
      >

        <Chip
          className={`BibleMapInfoEvent-Chip BibleMapInfoEvent-Chip-${color ? `has-color` : `no-color`}`}
          $color={color}
        >
          <ChipName
            className="BibleMapInfoEvent-ChipName"
          >
            {names[0].name}
          </ChipName>
          {` `}
          <ChipDate
            className="BibleMapInfoEvent-ChipDate"
          >
            {getDateAsString(dates[0])}
          </ChipDate>
        </Chip>

        <StyledExpandMoreIcon
          $explanded={explanded}
        />

      </ChipButton>

      <ExpandedSection
        className={`BibleMapInfoEvent-ExpandedSection-${explanded ? 'expanded' : 'collapsed'}`}
        $expanded={explanded}
      >

        {names.map(({ id, ...nameInfo }, idx) => (
          <BibleMapInfoName
            key={id}
            type="event"
            isPrimary={idx === 0}
            {...nameInfo}
          />
        ))}

        {dates.map(({ date, levelOfCertainty, notes }, idx) => (
          <DateInfo key={date}>
            <DateContainer>
              {idx === 0 &&
                <Date>
                  {getDateAsString({ date })}
                </Date>
              }
              {idx !== 0 &&
                <Also>
                  {i18nReact("Or Possibly: {{extra}}", {
                    extra: (
                      <AlsoDate>
                        {getDateAsString({ date })}
                      </AlsoDate>
                    ),
                  })}
                </Also>
              }
              {levelOfCertainty <= 6 && levelOfCertainty > 3 &&
                <DateUncertain>
                  {i18n("Uncertain")}
                </DateUncertain>
              }
              {levelOfCertainty <= 3 &&
                <DateHighlyUncertain>
                  {i18n("Highly uncertain")}
                </DateHighlyUncertain>
              }
            </DateContainer>
            <DateNotes>
              {notes}
            </DateNotes>
          </DateInfo>
        ))}

        {!!includePlace &&
          <Location>
            {i18nReact("Location: {{location}}", {
              location: (
                <LocationName
                  onClick={selectPlace}
                >
                  {getPrimaryName(place)}
                </LocationName>
              ),
            })}
          </Location>
        }

        {!!journeyId &&
          <Journey>
            {i18nReact("A part of {{journey}}", {
              journey: (
                <JourneyName
                  $color={color}
                  onClick={selectJourney}
                >
                  {getPrimaryName(journey)}
                </JourneyName>
              ),
            })}
          </Journey>
        }

        {mapNoteEvent.note === undefined &&
          <StyledButton
            onClick={createMapNote}
            startIcon={<SubjectIcon />}
          >
            {i18n("Add a note")}
          </StyledButton>
        }

        <StyledBibleMapNote
          key={id}
          mapNoteItem={mapNoteEvent}
          setMapNote={setMapNoteEvent}
        />

        {personIds.length > 0 &&
          <People>
            <Heading>
              {i18n("People Involved")}
            </Heading>
            <BibleMapInfoEventPersons>
              {personIds.map((personId, idx) => (
                <React.Fragment key={personId}>
                  {idx !== 0 && `, `}
                  <BibleMapInfoEventPerson
                    person={persons.find(({ id }) => id === personId)}
                    setSelectedPerson={setSelectedPerson}
                    setSelectedJourney={setSelectedJourney}
                  />
                </React.Fragment>
              ))}
            </BibleMapInfoEventPersons>
          </People>
        }

        {passages.length > 0 &&
          <References>
            <Heading>
              {i18n("References")}
            </Heading>
            <StyledContainerWithPassagePopper
              onTopOfAll
            >
              <BibleMapInfoEventReferences
                passages={passages}
              />
            </StyledContainerWithPassagePopper>
          </References>
        }

        {!!about &&
          <About>
            <Heading>
              {i18n("About")}
            </Heading>
            <AboutContent>
              {about}
            </AboutContent>
          </About>
        }

      </ExpandedSection>

    </Container>
  )
}

export default memo(BibleMapInfoEvent)