import { memo, useContext } from 'react'
import { i18n } from 'inline-i18n'
// import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import useInstanceValuesCallback from '../../../hooks/useInstanceValuesCallback'
import useThrottleCallback from '../../../hooks/useThrottleCallback'
import useSimpleToggle from '../../../hooks/useSimpleToggle'
import useEffectAsync from '../../../hooks/useEffectAsync'
import { LoggedInUserContext } from '../../../context/LoggedInUser'

import Editor, { isEmptyContent } from '../../editor/Editor'
import RelativeTime from '../../common/RelativeTime'
import MyPlanPromoSpot from '../../common/MyPlanPromoSpot'
import InfoDialog from '../../common/InfoDialog'

const Notes = styled.div`
`

const Heading = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LastUpdated = styled.div`
  font-size: 9px;
  font-weight: 300;
  text-align: right;
  margin: 5px 0 0;
`

const EditorContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main}44;
  padding: ${({ $editing }) => $editing ? `43px 12px 1px 12px` : `1px 12px`};
  border-radius: 5px;
  margin: 5px 0 0;
  overflow-y: auto;
  max-height: min(300px, calc(100vh - 250px));
  position: relative;

  .Editor-EditorContainer {
    margin: -3px 0;
  }

  .ToolbarPlugin-Container {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
  }

`

const EditNotesIconButton = styled(IconButton)`
  padding: 10px;
  margin: -20px -8px -20px 0;

  .MuiSvgIcon-root {
    font-size: 16px;
  }

  ${({ $editing, theme }) => !$editing ? `` : `
    color: ${theme.palette.primary.main};
  `};
`

const BibleMapNote = ({
  mapNoteItem,
  setMapNote,
  className,
}) => {

  const { hasMyPlan } = useContext(LoggedInUserContext) || {}

  const { note } = mapNoteItem || {}
  const hasNote = note !== undefined

  const goSave = useInstanceValuesCallback(note => setMapNote({ ...mapNoteItem, note, updatedAt: Date.now() }))
  const [ goThrottledSave, flushSave ] = useThrottleCallback(goSave, 500)
  const [ editing, toggleEditing ] = useSimpleToggle(false)
  const [ showGetMyPlan, toggleShowGetMyPlan ] = useSimpleToggle(false)

  useEffectAsync(
    () => {
      if(note === ``) {
        // set to editing if it was just created
        toggleEditing({ force: true })
      }
    },
    [ note ],
  )

  useEffectAsync(
    () => {
      if(
        !editing
        && (
          note === ``
          || isEmptyContent(note)
        )
      ) {
        goSave()
      }
    },
    [ editing ],
  )

  if(!mapNoteItem) return null

  return (
    <>

      {hasNote &&
        <Notes className={className}>
          <Heading className="BibleMapNote-Heading">
            {i18n("My Notes")}
            <EditNotesIconButton
              $editing={editing}
              onClick={hasMyPlan ? toggleEditing : toggleShowGetMyPlan}
            >
              <EditIcon />
            </EditNotesIconButton>
          </Heading>
          <EditorContainer
            $editing={editing}
            className="BibleMapPlaceInfo-EditorContainer"
          >
            <Editor
              key={editing ? `editing` : `not-editing`}
              editable={editing}
              initialContent={note}
              onUpdate={goThrottledSave}
              onBlur={flushSave}
              autoFocus
              maxLength={2000}
            />
          </EditorContainer>
          {!!mapNoteItem.updatedAt &&
            <LastUpdated>
              <RelativeTime date={mapNoteItem.updatedAt} />
            </LastUpdated>
          }
        </Notes>
      }

      <InfoDialog
        open={showGetMyPlan}
        onOkay={toggleShowGetMyPlan}
        showCloseIconButton
        title={i18n("Edit Map Notes")}
        explanation={
          <MyPlanPromoSpot
            subscribeToMessage={i18n("Subscribe to edit map notes and add more.")}
          />
        }
        fullScreenWhenMobileSize
      />

    </>
  )
}

export default memo(BibleMapNote)