import { memo, useCallback, useContext, useState } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import Tooltip from '@material-ui/core/Tooltip'

import { capitalize } from '../../utils/misc'
import { LoggedInUserContext } from '../../context/LoggedInUser'
import useSimpleToggle from '../../hooks/useSimpleToggle'
import useLogInCallback from '../../hooks/useLogInCallback'

import FadedLoading from '../common/FadedLoading'
import Loading from '../common/Loading'
import ConfirmDialog from '../common/ConfirmDialog'

import addReactionToStudyBibleItemMutation from '../../graphql/mutations/addReactionToStudyBibleItem'
import addReactionToCustomStudyBibleItemMutation from '../../graphql/mutations/addReactionToCustomStudyBibleItem'
import addReactionToStudyBibleItemCommentMutation from '../../graphql/mutations/addReactionToStudyBibleItemComment'
import addReactionToChannelItemMutation from '../../graphql/mutations/addReactionToChannelItem'
import removeReactionFromStudyBibleItemMutation from '../../graphql/mutations/removeReactionFromStudyBibleItem'
import removeReactionFromCustomStudyBibleItemMutation from '../../graphql/mutations/removeReactionFromCustomStudyBibleItem'
import removeReactionFromStudyBibleItemCommentMutation from '../../graphql/mutations/removeReactionFromStudyBibleItemComment'
import removeReactionFromChannelItemMutation from '../../graphql/mutations/removeReactionFromChannelItem'

const DEFAULT_REACTION_TYPES = [ `HEART` ]
const emptyArray = []

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 0 1px;
  margin-top: 4px;
`

const UsedTypeChip = styled.div`
  position: relative;
  display: flex;
  gap: 2px;
  align-items: center;
  border-radius: 100px;
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  padding: 3px 4px;
  font-size: 16px;
  line-height: 1;
  transition: opacity .25s ease-in-out, background-color .25s ease-in-out;
  cursor: default;

  ${({ $includesCurrentUsersReaction, theme }) => !$includesCurrentUsersReaction ? `` : `

    border-color: ${theme.palette.primary.main}aa;

    .Reactions-Count {
      background-color: ${theme.palette.primary.faded};
    }

  `}

  ${({ onClick }) => !onClick ? `` : `
    @media (hover: hover) {
      &:hover {
        opacity: .6;
        cursor: pointer;
      }
    }
  `}

`

const UsedType = styled.div`
  display: flex;
  justify-items: center;
  position: relative;
`

const Count = styled.div`
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  line-height: 14px;
  min-width: 14px;
  border-radius: 10px;
  padding: 0 3px;
`

const UnusedType = styled.div`
  position: relative;
  filter: grayscale(1);
  opacity: .6;
  transition: filter .25s ease-in-out, opacity .25s ease-in-out, transform .25s ease-in-out;
  font-size: 14px;
  padding: 2px 4px;
  display: flex;
  justify-items: center;

  ${({ onClick }) => !onClick ? `` : `
    &:hover {
      filter: grayscale(0);
      opacity: 1;
      transform: scale(1.35);
      cursor: pointer;
    }
  `}
`

const reactionCharsByType = {
  "THUMBS-UP": "👍",
  "THUMBS-DOWN": "👎",
  "EXCLAMATION": "❗",
  "HEART": "❤️",
  "QUESTION-MARK": "❓",
}

const Reactions = ({
  reactions,
  reactionToIdObj,  // should have single key-value pair; e.g. { studyBibleItemId: 21 }
  types=DEFAULT_REACTION_TYPES,
  allowNewReactions,
  refetch,
}) => {

  const user = useContext(LoggedInUserContext)

  const [ mutatingType, setMutatingType ] = useState()
  const [ dialogOpen, toggleDialogOpen ] = useSimpleToggle()
  const { logIn, navigatingToLogin } = useLogInCallback()

  const mutations = {
    addReactionToStudyBibleItem: useMutation(addReactionToStudyBibleItemMutation)[0],
    addReactionToCustomStudyBibleItem: useMutation(addReactionToCustomStudyBibleItemMutation)[0],
    addReactionToStudyBibleItemComment: useMutation(addReactionToStudyBibleItemCommentMutation)[0],
    addReactionToChannelItem: useMutation(addReactionToChannelItemMutation)[0],
    removeReactionFromStudyBibleItem: useMutation(removeReactionFromStudyBibleItemMutation)[0],
    removeReactionFromCustomStudyBibleItem: useMutation(removeReactionFromCustomStudyBibleItemMutation)[0],
    removeReactionFromStudyBibleItemComment: useMutation(removeReactionFromStudyBibleItemCommentMutation)[0],
    removeReactionFromChannelItem: useMutation(removeReactionFromChannelItemMutation)[0],
  }

  const mutationsKeyPortion = capitalize(Object.keys(reactionToIdObj)[0].replace(/Id$/, ``))
  const add = mutations[`addReactionTo${mutationsKeyPortion}`]
  const remove = mutations[`removeReactionFrom${mutationsKeyPortion}`]

  const usedTypes = types.filter(type => reactions[type])
  const unusedTypes = types.filter(type => !reactions[type])
  const { myReactionTypes=emptyArray } = reactions

  const onClick = useCallback(
    async ({ target }) => {

      // TODO: this is where I can check if they are allowed to comment/react for this channel
      if(!user) {
        toggleDialogOpen({ force: true })
        return
      }

      const type = target.closest(`[data-type]`).getAttribute('data-type')

      setMutatingType(type)

      if(myReactionTypes.includes(type)) {
        await remove({
          variables: {
            ...reactionToIdObj,
            type,
          },
        })
      } else {
        await add({
          variables: {
            ...reactionToIdObj,
            input: {
              type,
            },
          },
        })
      }

      refetch && await refetch()

      setMutatingType()

    },
    [ add, remove, reactionToIdObj, myReactionTypes, refetch, user, toggleDialogOpen ],
  )

  return (
    <Container>

      {usedTypes.map(type => (
        <Tooltip
          key={type}
          title={
            (!allowNewReactions || mutatingType)
              ? ``
              : (
                myReactionTypes.includes(type)
                    ? (
                      i18n("Remove your {{reaction_type}}", {
                        reaction_type: reactionCharsByType[type],
                      })
                    )
                    : (
                      i18n("Add your {{reaction_type}}", {
                        reaction_type: reactionCharsByType[type],
                      })
                    )
              )
          }
        >
          <UsedTypeChip
            data-type={type}
            onClick={(!allowNewReactions || mutatingType) ? null : onClick}
            $includesCurrentUsersReaction={myReactionTypes.includes(type)}
          >
            <UsedType className="Reactions-UsedType">
              {reactionCharsByType[type]}
            </UsedType>
            <Count className="Reactions-Count">
              {reactions[type]}
            </Count>
            {mutatingType === type && <FadedLoading size={10} />}
          </UsedTypeChip>
        </Tooltip>
      ))}

      {!!allowNewReactions && unusedTypes.map(type => (
        <Tooltip
          key={type}
          title={
            i18n("Add your {{reaction_type}}", {
              reaction_type: reactionCharsByType[type],
            })
          }
        >
          <UnusedType
            data-type={type}
            onClick={mutatingType ? null : onClick}
          >
            {reactionCharsByType[type]}
            {mutatingType === type && <Loading size={10} bgOpacity={0} />}
          </UnusedType>
        </Tooltip>
      ))}

      <ConfirmDialog
        open={dialogOpen}
        onCancel={toggleDialogOpen}
        confirmButtonLabel={i18n("Sign in / create an account")}
        onConfirm={logIn}
        loading={navigatingToLogin}
        title={i18n("Log In")}
        explanation={i18n("You must first sign in or create a free account in order to add a reaction.")}
      />

    </Container>
  )
}

export default memo(Reactions)