import { memo, useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import { useMeasure, usePrevious } from 'react-use'
import { toPng } from 'html-to-image'
import Divider from '@material-ui/core/Divider'

import moduleComponentsByType from '../modules/shared/moduleComponentsByType'

import PrintAndPdfHeader from './PrintAndPdfHeader'
import useInstanceValue from '../../hooks/useInstanceValue'
import useSetTimeout from '../../hooks/useSetTimeout'
import useEffectAsync from '../../hooks/useEffectAsync'
import useLayoutEffectAsync from '../../hooks/useLayoutEffectAsync'

const PDF_PREVIEW_PPI = 72
const PDF_PREVIEW_PPMM = PDF_PREVIEW_PPI / 25.4

const UNITS_BY_PAGE_SIZE = {
  letter: [ 8.5, 11 ],
  legal: [ 8.5, 14 ],
  tabloid: [ 11, 17 ],
  a3: [ 297, 420 ],
  a4: [ 210, 297 ],
  a5: [ 148, 210 ],
}

const PROPORTION_BY_PAGE_SIZE = {}
Object.keys(UNITS_BY_PAGE_SIZE).forEach(pageSize => {
  PROPORTION_BY_PAGE_SIZE[pageSize] = {
    portrait: UNITS_BY_PAGE_SIZE[pageSize][1] / UNITS_BY_PAGE_SIZE[pageSize][0],
    landscape: UNITS_BY_PAGE_SIZE[pageSize][0] / UNITS_BY_PAGE_SIZE[pageSize][1],
  }
})

const ScaleContainer = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
`

const Container = styled.div`
  width: ${({ $width }) => $width}px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transform-origin: 0 0;
  transform: scale(${({ $scale }) => $scale}) translateY(${({ $translateY }) => $translateY}px);
  
  ${({ $blackAndWhite }) => !$blackAndWhite ? `` : `
    filter:grayscale(100%);
  `}

  ${({ $whiteBackground }) => !$whiteBackground ? `` : `
    background: white;
  `}
`

const LogoImg = styled.img`
  height: 22px;
  margin: -2px 3px 2px 0;
  align-self: flex-end;

  ${({ $show }) => $show ? `` : `
    display: none;
  `}
`

const StyledDivider = styled(Divider)`
  position: relative;
  top: ${({ $top }) => $top}px;
  margin-top: -1px;  // make it zero height
  transform: translateY(${({ $translateY }) => $translateY}px);
  transform-origin: 0 0;
`

const CaptureArea = ({
  module,
  projectId,
  options,
  filterElements,
  setCaptureInfo,
}) => {

  const [ pageNum, setPageNum ] = useState()
  const [ moduleRendered, setModuleRendered ] = useState(false)
  const [ doFinalSize, setDoFinalSize ] = useState(false)

  const [ moduleLoaded, setModuleLoaded ] = useState(false)
  const onLoadModule = useCallback(() => setModuleLoaded(true), [])
  const [ logoLoaded, setLogoLoaded ] = useState(false)
  const onLoadLogo = useCallback(() => setLogoLoaded(true), [])
  const loaded = moduleLoaded && logoLoaded

  const scaleContainerRef = useRef()
  let [ containerRef, { height } ] = useMeasure()
  const headerRef = useRef()
  const getHeight = useInstanceValue(height)

  let basePageWidth
  let scale = (options.imageWidth / options.moduleWidth) / window.devicePixelRatio
  if(![ `png` ].includes(options.format)) {
    const unitsMultiplier = /^a/.test(options.pageSize) ? PDF_PREVIEW_PPMM : PDF_PREVIEW_PPI
    basePageWidth = (UNITS_BY_PAGE_SIZE[options.pageSize][options.orientation === `landscape` ? 1 : 0] * unitsMultiplier)
    scale = basePageWidth / (options.moduleWidth * window.devicePixelRatio)
  }

  const prevShowLogo = usePrevious(options.showLogo)
  const getLogoJustToggled = useInstanceValue(prevShowLogo !== options.showLogo)

  const Module = moduleComponentsByType[module.type]

  const [ setGetPngTimeout ] = useSetTimeout()

  const width = options.moduleWidth - (![ `png` ].includes(options.format) ? (options.margins * 2) / (basePageWidth / options.moduleWidth) : 0)
  const pageHeights = useRef([])

  // 300 dpi is generally enough, 400 dpi is definitely enough.
  // The following calculation with keep it at 400 dpi for almost two letter sized pages,
  // which produces a PDF file about 700kb, and then begin to reduce the dpi after that,
  // keeping that same file size until you get over ~10 pages.
  const pdfEnlargeFactor = Math.max(Math.min(180000 / height + 150, 400), 144) / PDF_PREVIEW_PPI
  const printEnlargeFactor = doFinalSize ? pdfEnlargeFactor : 1

  const getPng = async () => {
    const t = Date.now()
    const dataUrl = await toPng(
      scaleContainerRef.current,
      {
        filter: filterElements,  // useful for speed optimization
      },
    )
    // URL.createObjectURL(
    //   await toBlob(
    //     scaleContainerRef.current,
    //     {
    //       filter: filterElements,  // useful for speed optimization
    //     },
    //   )
    // )
    console.log(`png render time:`, Date.now() - t)
    return dataUrl
  }

  const deps = [
    options.format,
    options.showLogo,
    options.whiteBackground,
    options.blackAndWhite,
    basePageWidth,
    options.pageSize,
    options.orientation,
    options.margins,
    width,
    scale,
    loaded,
  ]

  useLayoutEffectAsync(
    () => {
      setModuleRendered(false)
      setPageNum()
    },
    deps,
  )

  useEffectAsync(
    async () => {
      if(!loaded) return
      await new Promise(resolve => setTimeout(resolve, 1))  // allow it to render
      setModuleRendered(true)
      setGetPngTimeout(
        async () => {
          const height = getHeight()

          if(options.format === `pdf`) {

            // calc page cuts
            const headerHeight = headerRef.current.getBoundingClientRect().height / scale
            pageHeights.current = [ headerHeight ]  // The header gets the 0 index
            const adjustedPairOfMargins = (options.margins * 2) / (basePageWidth / options.moduleWidth)
            const maxPageHeight = PROPORTION_BY_PAGE_SIZE[options.pageSize][options.orientation] * (width + adjustedPairOfMargins) - adjustedPairOfMargins
            const flipEditorBreakTextBlockSelector = `.public-DraftStyleDefault-block, .public-DraftStyleDefault-orderedListItem, .public-DraftStyleDefault-unorderedListItem`
            const printBreakBeforeTops = [
              ...(
                [ ...scaleContainerRef.current.querySelectorAll(`.print-break-block, .print-break-text-block, ${flipEditorBreakTextBlockSelector}`) ]
                  .map(el => {
                    // if(el.classList.contains(`print-break-text-block`)) {
                    if(el.matches(`.print-break-text-block, ${flipEditorBreakTextBlockSelector}`)) {
                      const range = document.createRange()
                      range.setStart(el, 0)
                      range.setEnd(el, el.childNodes.length)
                      const relevantRects = (
                        [ ...range.getClientRects() ]
                          // Count overlapping rects as one
                          .sort((a,b) => a.top - b.top)
                          .filter(({ top }, idx, ary) => (
                            !ary.slice(0,idx).some(prevRect => (
                              top < prevRect.top + prevRect.height
                            ))
                          ))
                      )
                      return relevantRects.map(({ top }) => top / scale)
                    }
                    return el.getBoundingClientRect().top / scale
                  })
                  .flat()
              ),
              height + headerHeight,
            ].sort((a,b) => b-a)
            let nextPageTop = headerHeight
            for(let idx=0; nextPageTop < height + headerHeight && idx < 100; idx++) {
              const maxPageHeightForThisPage = maxPageHeight - (idx === 0 ? headerHeight : 0)
              let newPageHeight = (printBreakBeforeTops.find(ht => ht <= nextPageTop + maxPageHeightForThisPage) || 0) - nextPageTop  // eslint-disable-line no-loop-func
              if(
                newPageHeight < maxPageHeightForThisPage / 2  // If we did not find a good spot to break on the second half of next page
                && newPageHeight + nextPageTop !== printBreakBeforeTops[0]  // And this is not the end of the image
              ) {
                // then do a full page
                newPageHeight = maxPageHeightForThisPage
              }
              pageHeights.current.push(newPageHeight)
              nextPageTop += newPageHeight
            }

            setCaptureInfo({
              options,
              basePageWidth,
              scale,
              numPages: pageHeights.current.length - 1,
              pageHeightToWidthProportion: PROPORTION_BY_PAGE_SIZE[options.pageSize][options.orientation],
              getDataUrlForPage: async (pageNum, doFinalSize) => {
                if(doFinalSize) setDoFinalSize(true)
                setPageNum(pageNum)
                await new Promise(resolve => setTimeout(resolve, 50))  // allow it to render (1 was working for Chrome, but not for Safari; so I changed it to 50)
                return await getPng()
              },
            })

          } else {

            setCaptureInfo({
              options,
              imageHeight: height * window.devicePixelRatio * scale,
              dataUrl: await getPng(),
            })

          }
        },
        getLogoJustToggled() ? 100 : 1,
      )
    },
    deps,
  )

  if(pageNum !== undefined) {
    height = pageHeights.current[pageNum]
  }

  const translateY = -pageHeights.current.slice(0, pageNum || 0).reduce((total,ht) => total+ht, 0)

  return (
    <ScaleContainer
      $width={width * scale * printEnlargeFactor}
      $height={height * scale * printEnlargeFactor}
      ref={scaleContainerRef}
    >

      {![ `png` ].includes(options.format) &&
        <>
          <PrintAndPdfHeader
            ref={headerRef}
            module={module}
            $width={width * scale}
            $scale={printEnlargeFactor}
            $translateY={translateY}
            $marginBottom={doFinalSize ? ((printEnlargeFactor - 1) * pageHeights.current[0] * scale) : 0}
          />
          <StyledDivider
            $top={printEnlargeFactor * pageHeights.current[0] * scale * -.25}
            $translateY={translateY * scale * printEnlargeFactor}
          />
        </>
      }

      <Container
        ref={containerRef}
        $width={width}
        $scale={moduleRendered ? (scale * printEnlargeFactor) : 1}
        $translateY={translateY}
        $whiteBackground={options.whiteBackground}
        $blackAndWhite={options.blackAndWhite}
      >

        <Module
          module={module}
          projectId={projectId}
          onLoad={onLoadModule}
          printOrDownloadInfo={{}}
        />

        <LogoImg
          $show={options.showLogo && [ `png` ].includes(options.format)}
          src="/logo.svg"
          onLoad={onLoadLogo}
        />

      </Container>
    </ScaleContainer>
  )
}

export default memo(CaptureArea)

// This may be needed for doing an svg
// let logoDataUrl = ``
// ;(async () => {
//   const response = await fetch("/logo.svg")
//   const blob = await response.blob()
//   const reader = new FileReader()
//   await new Promise((resolve, reject) => {
//     reader.addEventListener('error', () => reject(new Error('Error loading resource with FileLoader')))
//     reader.addEventListener('load', () => resolve())
//     reader.readAsDataURL(blob)
//   })
//   logoDataUrl = new URL(reader.result)
// })()