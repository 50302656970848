import { memo, useState } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import BibleMapInfoName from './BibleMapInfoName'
import BibleMapInfoBody from './BibleMapInfoBody'
import BibleMapInfoEvent from './BibleMapInfoEvent'
import BibleMapNote from './BibleMapNote'

const About = styled.div`
`

const Heading = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
`

const AboutContent = styled.div`
  font-weight: 300;
  color: ${({ theme }) => theme.palette.grey[700]};
`

const Events = styled.div`
`

const EventItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 5px 0;
`

const BibleMapJourneyInfo = ({
  journey,
  places,
  journeys,
  persons,
  setSelectedJourney,
  setSelectedPerson,
  setSelectedPlace,
  eventsByJourneyId,
  mapNoteJourney,
  setMapNoteJourney,
}) => {

  const [ expandedEventId, setExpandedEventId ] = useState()

  return (
    <BibleMapInfoBody>

      {journey.names.map(({ id, ...nameInfo }, idx) => (
        <BibleMapInfoName
          key={id}
          type="journey"
          skipLabel={idx === 0}
          {...nameInfo}
        />
      ))}

      {!!journey.about &&
        <About>
          <Heading>
            {i18n("About")}
          </Heading>
          <AboutContent>
            {journey.about}
          </AboutContent>
        </About>
      }

      <BibleMapNote
        key={journey.id}
        mapNoteItem={mapNoteJourney}
        setMapNote={setMapNoteJourney}
      />

      <Events>
        <Heading>
          {i18n("Events")}
        </Heading>
        <EventItems>
          {eventsByJourneyId[journey.id].map(event => (
            <BibleMapInfoEvent
              key={event.id}
              {...event}
              explanded={expandedEventId === event.id}
              setExpandedEventId={setExpandedEventId}
              setSelectedJourney={setSelectedJourney}
              setSelectedPerson={setSelectedPerson}
              journeys={journeys}
              persons={persons}
              includePlace
              setSelectedPlace={setSelectedPlace}
            />
          ))}
        </EventItems>
      </Events>

    </BibleMapInfoBody>
  )
}

export default memo(BibleMapJourneyInfo)