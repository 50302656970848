import { useCallback } from 'react'

import useShortcuts from './useShortcuts'

import keyDownSpace from '../components/common/freehandContainerShortcuts/keyDownSpace'

const keyDownFuncs = {
  keyDownSpace,
}

const useProjectShortcuts = params => {

  // const {  } = params

  const inCorrectContext = useCallback(
    event => {

      return (
        !document.activeElement
        || !document.activeElement.closest(`input[type="text"], textarea, [contenteditable]`)
      )

    },
    [],
  )

  useShortcuts({
    keyDownFuncs,
    inCorrectContext,
    ...params,
  })

}

export default useProjectShortcuts